import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Button,
  Skeleton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import { MapPin, Users, Buildings, ArrowUpRight, User } from "phosphor-react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TriVerbalSection from "./TriVerbalSection";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "axios";
import ReactGA from "react-ga4";
import googleLogo from "../assets/svg/google.png";

export default function TriVerbal({ phrase, show, setShow }) {
  const [summary, setSummary] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [com, setCom] = useState(null);
  const [score, setScore] = useState(null);
  const [pendingRequest, setPendingRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [hover, setHover] = useState(false);
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });
  const handleOpenDialog = (event, content) => {
    const rect = event.target.getBoundingClientRect();
    setDialogPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX + rect.width,
    });
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleIconClick = (url) => {
    window.open(url, "_blank");
  };
  const SimpleDialog = ({ open, onClose, content, position }) => {
    const leftPercent = (position.left / window.innerWidth) * 100;
    const adjustedLeftPercent = leftPercent > 85 ? 65 : leftPercent;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        BackdropProps={{
          sx: {
            backgroundColor: "transparent",
            opacity: 0,
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            width: 310,
            height: "auto",
            borderRadius: "12px",
            opacity: 1,
            boxShadow: "0px 4px 8px 0px rgba(55, 55, 55, 0.08)",
            position: "absolute",
            top: `${position.top - 400}px`,
            left: `${adjustedLeftPercent}%`,
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {content.logo_url && (
                <img
                  src={content.logo_url}
                  alt={`${content.name} Logo`}
                  style={{ width: 24, height: 24 }}
                />
              )}
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Archivo",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "21.76px",
                  textAlign: "left",
                }}
              >
                {content.name}
              </Typography>
            </Stack>
            {content.website_url && (
              <IconButton
                onClick={() => handleIconClick(content.website_url)}
                sx={{
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
                color="#21272A"
              >
                <ArrowUpwardIcon
                  sx={{ transform: "rotate(45deg)", fontSize: "30px" }}
                  color="#21272A"
                />
              </IconButton>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
            <DialogContentText style={{ fontSize: "14px" }}>
              {content.description}
            </DialogContentText>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              <User color="#697077" size={20} weight="fill" />
              <span style={{ fontSize: "12px" }}>{content.founder}</span>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <MapPin color="#697077" size={20} weight="fill" />
              <span style={{ fontSize: "12px" }}>{content.location}</span>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <Users color="#697077" size={20} weight="fill" />
              <span style={{ fontSize: "12px" }}>{content.num_employees}</span>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <Buildings color="#697077" size={20} weight="fill" />
              <span style={{ fontSize: "12px" }}>
                Founded {new Date(content.founded_at).getFullYear()}
              </span>
            </Stack>
          {content.categories &&
            content.categories.filter((category) => category).length > 0 && (
              <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
                {content.categories
                  .filter((category) => category)
                  .map((category, index) => (
                    <Chip
                      key={index}
                      label={category}
                      variant="outlined"
                      sx={{
                        color: "#C1C7CD",
                        borderRadius: 1,
                        borderColor: "#C1C7CD",
                        "& .MuiChip-label": {
                          color: "inherit",
                        },
                      }}
                    />
                  ))}
              </Box>
            )}
        </DialogContent>
      </Dialog>
    );
  };

  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    // Create a URLSearchParams object from the current window location
    const queryParams = new URLSearchParams(window.location.search);
    const keywordParam = queryParams.get("keyword");

    // Set the keyword state
    if (keywordParam) {
      setKeyword(keywordParam.toLowerCase());
    }
  }, []);
  // helper functions
  const catTexts = (texts) => {
    if (texts.length > 2) {
      return texts.slice(0, -1).join(", ") + ", and " + texts[texts.length - 1];
    }
    return texts.join(" and "); // length 2
  };
  const getIndustryText = () => {
    if (score !== null && industries) {
      if (score <= 0.2) {
        if (industries.length > 1) {
          return `This technology is in its infancy, primarily suitable for academic research and conceptual exploration in the ${catTexts(
            industries
          )} industries. Investment at this stage is highly speculative and should focus on long-term R&D.`;
        } else {
          return `This technology is in its infancy, primarily suitable for academic research and conceptual exploration in the ${industries[0]} industry. Investment at this stage is highly speculative and should focus on long-term R&D.`;
        }
      } else if (score <= 0.4) {
        if (industries.length > 1) {
          return `The technology shows promise but is still in the prototyping phase. It may have niche applications in the ${catTexts(
            industries
          )} that require further development and testing. Early-stage investment is advisable.`;
        } else {
          return `The technology shows promise but is still in the prototyping phase. It may have niche applications in the ${industries[0]} that require further development and testing. Early-stage investment is advisable.`;
        }
      } else if (score <= 0.6) {
        if (industries.length > 1) {
          return `This technology has undergone initial validation and could be piloted for specific use-cases in the ${catTexts(
            industries
          )}. It's a candidate for targeted investment and partnership for further refinement.`;
        } else {
          return `This technology has undergone initial validation and could be piloted for specific use-cases in the ${industries[0]}. It's a candidate for targeted investment and partnership for further refinement.`;
        }
      } else if (score <= 0.8) {
        if (industries.length > 1) {
          return `The technology is mature enough for broader adoption in the ${catTexts(
            industries
          )}. It has proven use-cases and offers a safer bet for investment and immediate implementation.`;
        } else {
          return `This technology has undergone initial validation and could be piloted for specific use-cases in the ${industries[0]}. It's a candidate for targeted investment and partnership for further refinement.`;
        }
      } else if (score <= 1.0) {
        if (industries.length > 1) {
          return `This technology is an industry leader with high market acceptance, making it a strategic investment for immediate adoption and scaling in the ${catTexts(
            industries
          )} sectors. It's a must-have for maintaining competitive advantage.`;
        } else {
          return `This technology has undergone initial validation and could be piloted for specific use-cases in the ${industries[0]}. It's a candidate for targeted investment and partnership for further refinement.`;
        }
      }
    }
    return "";
  };

  // API header
  const accessToken = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  // fetch score API
  useEffect(() => {
    // reset states
    setSummary(null);
    setIndustries(null);
    setCompanies(null);
    setScore(null);
    setCom(null);
    // abort pending requests
    if (pendingRequest) {
      pendingRequest.abort();
    }
    setPendingRequest(null);
    // API calls
    const controller = new AbortController();
    const fetchSummary = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/tra/summary?phrases=${phrase}`,
        { headers, signal: controller.signal }
      );
      return res;
    };
    const fetchIndustries = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/tra/industries?phrases=${phrase}`,
        { headers, signal: controller.signal }
      );
      return res;
    };
    const fetchCompanies = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/tra/companies?phrases=${phrase}`,
        { headers, signal: controller.signal }
      );
      return res;
    };
    const fetchScore = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/technology_readiness_index?phrases=${phrase}`,
        { headers, signal: controller.signal }
      );
      return res;
    };
    const fetchCompany = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/technology/readiness/companies?phrases=${phrase}`,
        { headers, signal: controller.signal }
      );
      return res;
    };
    //
    // handle request
    fetchSummary()
      .then(({ data }) => {
        // update score
        setSummary(data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 403) {
          localStorage.removeItem("access_token"); // Remove access token
          alert("Please Login Again.");
          window.location.reload();
        } else {
          console.warn(e);
        }
      });
    fetchIndustries().then(({ data }) => {
      // update score
      setIndustries(data);
    });
    fetchCompanies().then(({ data }) => {
      // update score
      setCompanies(data);
    });
    fetchCompany().then(({ data }) => {
      // update score
      setCom(data);
    });
    fetchScore().then(({ data }) => {
      // update score
      setScore(Math.round((data + Number.EPSILON) * 100) / 100);
    });
    // eslint-disable-next-line
  }, [phrase]);
  const trackGoogleAnalyticsEvent = (category, action, label, value = "") => {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };
  return (
    <Box
      sx={{
        fontFamily: "'Glory', sans-serif",
      }}
    >
      {/* Top */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Button
          variant="text"
          endIcon={<DownloadIcon />}
          sx={{
            mr: "-8px",
            textTransform: "none",
            fontFamily: '"Glory", sans-serif',
            fontWeight: "500",
            color: "#3D766F",
          }}
          onClick={() => {
            trackGoogleAnalyticsEvent(
              "TRA",
              "Export insight snapshot",
              "Export insight Button Clicked"
            );
          }}
        >
          Export insight snapshot
        </Button>
      </Box>
      {/* Middle Sections */}
      <Stack spacing={2}>
        {summary ? (
          <TriVerbalSection
            title={"Technology Summary"}
            content={summary}
          ></TriVerbalSection>
        ) : (
          <Stack spacing={2}>
            <Skeleton variant="rounded" height={"1.25rem"}></Skeleton>
            <Skeleton variant="rounded" height={"5rem"}></Skeleton>
          </Stack>
        )}
        {industries && score !== null ? (
          <TriVerbalSection
            title={"Scalability & Applicable Industries"}
            content={getIndustryText()}
          ></TriVerbalSection>
        ) : (
          <Stack spacing={2}>
            <Skeleton variant="rounded" height={"1.25rem"}></Skeleton>
            <Skeleton variant="rounded" height={"5rem"}></Skeleton>
          </Stack>
        )}
        {com ? (
          <TriVerbalSection
            title={"Commercialize Companies"}
            content={[
              <span style={{ fontWeight: 500 }}>Mature Companies | </span>,
              com.mature.map((company, index) => (
                <span
                  key={index}
                  style={{ cursor: "pointer", color: "#0EA996" }}
                  onMouseOver={(event) => handleOpenDialog(event, company)}
                  // }
                >
                  {company.name}
                  {index < com?.mature.length - 1 ? ", " : ""}
                </span>
              )),
              <br />,
              <span style={{ fontWeight: 500 }}>Startups | </span>,
              com.startup.map((company, index) => (
                <span
                  key={index}
                  style={{ cursor: "pointer", color: "#0EA996" }}
                  onMouseOver={(event) => handleOpenDialog(event, company)}
                >
                  {company.name}
                  {index < com.startup.length - 1 ? ", " : ""}
                </span>
              )),
            ]}
          />
        ) : (
          <Stack spacing={2}>
            <Skeleton variant="rounded" height={"1.25rem"}></Skeleton>
            <Skeleton variant="rounded" height={"5rem"}></Skeleton>
          </Stack>
        )}
      </Stack>
      {/* Bottom */}
      <Button
        variant="text"
        endIcon={show ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={{
          mt: "1rem",
          ml: "-8px",
          textTransform: "none",
          fontFamily: '"Glory", sans-serif',
          fontWeight: "500",
          color: "#435452",
        }}
        onClick={() => {
          setShow((prevShow) => !prevShow);
          trackGoogleAnalyticsEvent(
            "TRA",
            "Learn more detail",
            "Learn more detail Button Clicked"
          );
        }}
      >
        {!show ? "Learn more detail" : "Hide details"}
      </Button>
      <SimpleDialog
        open={openDialog}
        onClose={handleCloseDialog}
        content={dialogContent}
        position={dialogPosition}
      />
    </Box>
  );
}
