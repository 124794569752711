import React, { useState, useEffect, useRef, useCallback } from "react";
import { ReactComponent as LogoSVG } from "../assets/svg/logo5.svg";
import { ReactComponent as NewSVG } from "../assets/svg/bg_epic4.svg";
import "../App.css";

import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Checkbox,
  IconButton,
  Collapse,
  InputBase,
  Button,
  Typography,
  Modal,
  ButtonGroup,
  Autocomplete,
  TextField
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import ReactEcharts from "echarts-for-react";
import axios from "axios";
import TriCard from "./TriCard";
import TriVerbal from "./TriVerbal";
import { Grid, Container } from "@mui/material";
import ReactGA from "react-ga4";
import useAnalyticsPageTracker from "../hooks/useAnalyticsPageTracker";
import { jwtDecode } from "jwt-decode";
import { debounce } from 'lodash';

echarts.use([LineChart, GridComponent, TooltipComponent, CanvasRenderer]);

function updateFormatDateRangesForXAxis(ranges) {
  return ranges.map((range) => {
    const startDate = new Date(range.start);
    const endDate = new Date(range.end);

    return `${startDate.getFullYear()}/${
      startDate.getMonth() + 1
    }/${startDate.getDate()}-${endDate.getFullYear()}/${
      endDate.getMonth() + 1
    }/${endDate.getDate()}`;
  });
}

function Navbar({ openModal }) {
  return (
    <div className="navbar">
      <Link to="/" style={{ pointerEvents: "auto" }}>
        <LogoSVG />
      </Link>

      <div className="search-icon-wrapper" onClick={openModal}>
        <SearchIcon className="tech-search-icon" />
      </div>
    </div>
  );
}

const getColor = (type) => {
  switch (type) {
    case "Paper":
      return "#7BC6DF";
    case "Grant":
      return "#8ADAD6";
    case "Patent":
      return "#9ADED6";
    case "Enterprise":
      return "#64ABA3";
    case "Forum":
      return "#3D766F";
    default:
      return "transparent";
  }
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function TechReadiness() {
  useAnalyticsPageTracker();
  const query = useQuery();
  const navigate = useNavigate();
  const keywords = query.get("keyword");
  const [searchTerm, setSearchTerm] = useState(keywords || "");
  const [searchValue, setSearchValue] = useState(keywords || "");
  const [period, setPeriod] = useState("730");
  const echartsRef = React.useRef(null);
  const [show, setShow] = useState(false);
  const [timeRanges, setTimeRanges] = useState([]);
  const [bar, setBar] = useState([]);
  const [dataType, setDataType] = useState({
    paper: true,
    grant: true,
    patent: true,
    enterprise: true,
    forum: true,
  });
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const latestInputValueRef = useRef("");
  const [searchHistory, setSearchHistory] = useState([]);
  
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const isExpired = decodedToken.exp * 1000 < new Date().getTime();
      if (isExpired) {
        navigate("/Login?reset=accountExpired");
      }
    }
  }, [navigate]);

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleDataTypeChange = (event) => {
    setDataType({ ...dataType, [event.target.name]: event.target.checked });
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
    setShow(false);
  };
  useEffect(() => {
    const fetchSearchHistory = async () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_V2}/v1/search_history`,
          { headers }
        );
        setSearchHistory(response.data.results);
      } catch (error) {
        console.error("Error fetching search history:", error);
      }
    };

    fetchSearchHistory();
  }, []);
  const fetchAutocompleteOptions = async (prefix) => {
    if (!prefix) return;
    setLoading(true);

    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v1/autocomplete`,
        {
          headers,
          params: { prefix: prefix },
        }
      );

      // Only update options if the input value hasn't changed
      if (prefix === latestInputValueRef.current) {
        setOptions(response.data.results);
      }
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    } finally {
      setLoading(false);
    }
  };
  const debouncedFetchAutocompleteOptions = useCallback(
    debounce((newInputValue) => fetchAutocompleteOptions(newInputValue), 300),
    []
  );
  const handleInputChange = (newInputValue) => {
    setConcept(newInputValue);
    latestInputValueRef.current = newInputValue;
  
    if (!newInputValue) {
      debouncedFetchAutocompleteOptions.cancel();
      setOptions(searchHistory); // Show search history
      setValue(null);
    } else {
      debouncedFetchAutocompleteOptions(newInputValue);
    }
  };
  // const handleOptionChange = (event, newValue) => {
  //   setValue(newValue);

  //   // If an option is selected, set the searchTerm based on Abbv or Phrase
  //   if (typeof newValue === "object" && newValue !== null) {
  //     setSearchTerm(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //     setSearchValue(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //     setConcept(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //   } else if (typeof newValue === "string") {
  //     // If a string is entered (free solo), use it directly
  //     setSearchTerm(newValue);
  //     setSearchValue(newValue);
  //     setConcept(newValue);
  //   } else {
  //     // setSearchTerm("");
  //   }
  // };
  const handleInputFocus = () => {
    if (!concept) {
      setOptions(searchHistory); // Show search history on focus
    }
  };
  const handleOptionChange = (event, newValue) => {
    setValue(newValue);
    
    // If an option is selected, set the searchTerm based on Abbv or Phrase
    let termToSearch = '';
    if (typeof newValue === 'object' && newValue !== null) {
      termToSearch = newValue.Abbv ? newValue.Abbv : newValue.Phrase;
      setSearchTerm(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
      setSearchValue(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
      setConcept(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
    } else if (typeof newValue === 'string') {
      // If a string is entered (free solo), use it directly
      termToSearch = newValue;
      setSearchTerm(newValue);
      setSearchValue(newValue);
      setConcept(newValue);
    }
  
    if (termToSearch) {
      ReactGA.event("search_performed", {
        category: "Autocomplete Option Clicked",
        label: "Autocomplete Clicked",
        search_term: termToSearch,
      });
      // Navigate to the appropriate page based on the selected button
      if (selectedButton === "TechScouting") {
        navigate(`/TechScoutingResult?keywords=${encodeURIComponent(termToSearch)}&activity=search`);
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(termToSearch)}&activity=search`);
      }
    } else {
      setSearchTerm('');
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("TechReadiness");
  const data = {
    Paper: [],
    Grant: [],
    Patent: [],
    Enterprise: [],
    Forum: [],
  };
  const [openRow, setOpenRow] = useState(Object.keys(data)[0]);
  const handleToggle = (type) => {
    if (openRow === type) {
      setOpenRow(null);
    } else {
      setOpenRow(type);
    }
  };
  const [concept, setConcept] = useState("");
  const trackClicksEvent = (category, action, label, value = "") => {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };
  const TrackGoogleAnalyticsEvent = (category, event_name, label, data) => {
    let event_params = {
      category,
      label,
      ...data,
    };
    // Send GA4 Event
    ReactGA.event(event_name, event_params);
  };
  const handleSearch = () => {
    setSearchValue(concept);
    setSearchTerm(concept);
    TrackGoogleAnalyticsEvent(
      selectedButton,
      "search_performed",
      "Search Term",
      { search_term: concept }
    );
    if (selectedButton === "TechScouting") {
      navigate(`/TechScoutingResult?keywords=${concept}&activity=search`);
    } else if (selectedButton === "TechReadiness") {
      navigate(`/TechReadiness?keyword=${concept}&activity=search`);
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const colors = ["#E1EAFF", "#B4DEF3", "#B4DDF3", "#CAF6CC", "#9CE5CB"];
  const [originalColors, setOriginalColors] = useState([]);
  const [dateInterval, setDateInterval] = useState({
    start: "2022-01-01T00:00:00Z",
    end: "2022-01-31T23:59:59Z",
  });
  const [sumForSource, setSumForSource] = useState({});
  const [option, setOption] = useState({});
  function getUnixTimestamp(dateString) {
    return Math.floor(new Date(dateString).getTime() / 1000);
  }
  const paperColor = "#E1EAFF"; // Color for Paper section
  const patentColor = "#CAF6CC"; // Color for Patent section

  function updateGenerateSeriesFromData(data) {
    let sources = new Set();
    data.forEach((item) => {
      item.forEach((doc) => {
        if (doc.key === "USPTO") {
          sources.add("Patent");
        } else {
          sources.add(doc.key);
        }
      });
    });
    sources = [...sources];

    const paperTotalSeries = {
      name: "Paper Total",
      type: "bar",
      barWidth: 18,
      stack: "TotalStack",
      data: data.map((timeRange) =>
        timeRange.reduce(
          (sum, doc) => (doc.key !== "USPTO" ? sum + doc.doc_count : sum),
          0
        )
      ),
      itemStyle: {
        color: "#9ADED6",
      },
    };

    const patentTotalSeries = {
      name: "Patent Total",
      type: "bar",
      barWidth: 18,
      stack: "TotalStack",
      data: data.map((timeRange) =>
        timeRange.reduce(
          (sum, doc) => (doc.key === "USPTO" ? sum + doc.doc_count : sum),
          0
        )
      ),
      itemStyle: {
        color: "#5BC0EB",
      },
    };
    const grantTotalSeries = {
      name: "Grant Total",
      type: "bar",
      barWidth: 18,
      stack: "TotalStack",
      data: data.map((timeRange) =>
        timeRange.reduce((sum, doc) => {
          return doc.key === "Grants.gov" ? sum + doc.doc_count : sum;
        }, 0)
      ),
      itemStyle: {
        color: "#5BC0EB",
      },
    };

    const sourceSeries = sources.map((source, index) => ({
      name: source,
      type: "bar",
      barWidth: 12,
      stack: "SourceStack",
      data: [],
      itemStyle: {
        color:
          source === "Patent" ? patentColor : colors[index % colors.length],
      },
    }));

    data.forEach((timeRange) => {
      sourceSeries.forEach((serie) => {
        if (serie.name === "Patent") {
          const doc = timeRange.find((doc) => doc.key === "USPTO");
          serie.data.push(doc ? doc.doc_count : 0);
        } else if (serie.name === "Grant") {
          const doc = timeRange.find((doc) => doc.key === "Grants.gov");
          serie.data.push(doc ? doc.doc_count : 0);
        } else {
          const doc = timeRange.find((doc) => doc.key === serie.name);
          serie.data.push(doc ? doc.doc_count : 0);
        }
      });
    });
    sourceSeries.sort((a, b) =>
      a.name === "Patent" ? 1 : b.name === "Patent" ? -1 : 0
    );
    return [
      paperTotalSeries,
      patentTotalSeries,
      grantTotalSeries,
      ...sourceSeries,
    ];
  }

  useEffect(() => {
    const generatedSeries = updateGenerateSeriesFromData(bar);
    setOriginalColors(generatedSeries.map((series) => series.itemStyle.color));
    setOption({
      tooltip: {
        trigger: "axis",
        useHTML: true,
        padding: [10, 80, 1, 10],
        formatter: function (params) {
          var style =
            "font-family: Glory; font-size: 14px; font-weight: 500; line-height: 22px; letter-spacing: 0em; text-align: left;";
          var result =
            '<div style="display: flex; align-items: center; ' + style + '">';
          result += "</div>";

          var total = 0;
          var paperTotal = 0;
          var patentTotal = 0;
          var grantTotal = 0;
          var paperCounts = [];
          var patentCounts = [];
          var grantCounts = [];
          var otherCounts = 0;

          params.forEach(function (item) {
            if (item.seriesName === "Paper Total") {
              paperTotal = item.data;
            } else if (item.seriesName === "Patent Total") {
              patentTotal = item.data;
            } else if (item.seriesName === "Grant Total") {
              grantTotal = item.data;
            } else if (item.seriesName === "Patent") {
              patentCounts.push({
                name: "USPTO",
                value: item.data,
                marker: item.marker,
              });
            } else if (item.seriesName === "Grants.gov") {
              grantCounts.push({
                name: "Grants.gov",
                value: item.data,
                marker: item.marker,
              });
            } else {
              paperCounts.push({
                name: item.seriesName,
                value: item.data,
                marker: item.marker,
              });
            }
          });

          // Sort the paperCounts array based on the value
          paperCounts.sort((a, b) => b.value - a.value);

          // Show top 5 papers and group the rest under "Other"
          var top5PaperCounts = paperCounts.slice(0, 5);
          otherCounts = paperCounts
            .slice(5)
            .reduce((sum, item) => sum + item.value, 0);

          total = paperTotal + patentTotal + grantTotal;
          result +=
            '<div style="text-align: left; padding-top: 5px; padding-bottom: 10px;"><strong>Total: ' +
            total +
            "</strong></div>";
          result += '<div style="display: flex; align-items: center;">';
          result +=
            '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: #9ADED6; margin-right: 8px;"></div>';
          result +=
            '<div style="text-align: left;"><strong>Paper: ' +
            paperTotal +
            "</strong></div>";
          result += "</div>";

          top5PaperCounts.forEach(function (item, index) {
            result +=
              "<pre>    <div style='width: 10px; height: 10px; border-radius: 50%; background-color: " +
              colors[index % colors.length] +
              "; margin-right: 8px; display: inline-block;'></div>" +
              item.name +
              ": " +
              item.value +
              "</pre>";
          });

          if (otherCounts > 0) {
            result +=
              "<pre>    <div style='width: 10px; height: 10px; border-radius: 50%; background-color: gray; margin-right: 8px; display: inline-block;'></div>Other: " +
              otherCounts +
              "</pre>";
          }

          result += '<div style="display: flex; align-items: center;">';
          result +=
            '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: #5BC0EB; margin-right: 8px;"></div>';
          result +=
            '<div style="text-align: left; "><strong>Patent: ' +
            patentTotal +
            "</strong></div>";
          result += "</div>";

          patentCounts.forEach(function (item) {
            result +=
              "<pre>    " +
              item.marker +
              item.name +
              ": " +
              item.value +
              "</pre>";
          });

          result += '<div style="display: flex; align-items: center;">';
          result +=
            '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: #6BE0EB; margin-right: 8px;"></div>';
          result +=
            '<div style="text-align: left; "><strong>Grant: ' +
            grantTotal +
            "</strong></div>";
          result += "</div>";

          grantCounts.forEach(function (item) {
            result +=
              "<pre>    " +
              item.marker +
              item.name +
              ": " +
              item.value +
              "</pre>";
          });

          return result;
        },
      },
      grid: {
        left: "3%",
        right: "10%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: updateFormatDateRangesForXAxis(timeRanges),
          name: "Timeline",
          nameTextStyle: {
            color: "black",
            fontSize: "18px",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Times",
          nameGap: 15,
          nameTextStyle: {
            color: "black",
            fontSize: "16px",
          },
        },
      ],
      series: updateGenerateSeriesFromData(bar),
      barGap: "0%", // No space between bars of the same stack
      barCategoryGap: "0%", // No space between different stacks
    }); // eslint-disable-next-line
  }, [period, timeRanges]);
  // useEffect(() => {
  //   const calculatedSum = updateTransformData(bar).reduce((acc, curr) => {
  //     const source = Object.keys(curr.perSource)[0];

  //     if (!acc[source]) {
  //       acc[source] = curr.total;
  //     } else {
  //       acc[source] += curr.total;
  //     }

  //     return acc;
  //   }, {});
  //   setSumForSource(calculatedSum);
  //   // eslint-disable-next-line
  // }, [timeRanges]);
  useEffect(() => {
    const aggregatedData = bar.flat().reduce((acc, curr) => {
      if (!acc[curr.type]) {
        acc[curr.type] = {};
      }
      if (!acc[curr.type][curr.key]) {
        acc[curr.type][curr.key] = curr.doc_count;
      } else {
        acc[curr.type][curr.key] += curr.doc_count;
      }
      return acc;
    }, {});
    const sortedData = Object.keys(aggregatedData).reduce((acc, type) => {
      const sortedKeys = Object.entries(aggregatedData[type]).sort(
        (a, b) => b[1] - a[1]
      );
      acc[type] = Object.fromEntries(sortedKeys);
      return acc;
    }, {});
    setSumForSource(sortedData);
  }, [timeRanges]);
  function sumPaperTotals(papers) {
    return papers.reduce((acc, paper) => acc + paper.total, 0);
  }

  const transformedData = {
    Paper: [],
    Grant: [],
    Patent: [],
    Enterprise: [],
    Forum: [],
  };
  function updateTransformData(data) {
    return data.map((item) => {
      const total = item.reduce((sum, doc) => sum + doc.doc_count, 0);
      const perSource = item.reduce((acc, doc) => {
        acc[doc.key] = doc.doc_count;
        return acc;
      }, {});

      return { total, perSource };
    });
  }

  // Object.keys(sumForSource).forEach((key) => {
  //   if (key !== "undefined") {
  //     // Filter out the 'undefined' key
  //     transformedData.Paper.push({
  //       source: key,
  //       total: sumForSource[key],
  //     });
  //   }
  // });
  Object.keys(sumForSource).forEach((type) => {
    Object.entries(sumForSource[type]).forEach(([key, total]) => {
      if (transformedData[type]) {
        transformedData[type].push({
          source: key,
          total: total,
        });
      }
    });
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setSearchTerm(searchValue);
      const category = selectedButton; // "TechScouting" or "TechReadiness"
      const event_name = "search_performed";

      // Triggering the GA event
      TrackGoogleAnalyticsEvent(category, event_name, "Search Term", {
        search_term: searchValue,
      });
      if (selectedButton === "TechScouting") {
        navigate(
          `/TechScoutingResult?keywords=${encodeURIComponent(searchValue)}&activity=search`
        );
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(searchValue)}&activity=search`);
      }
    }
  };
  const [lastClicked, setLastClicked] = useState(null);
  const [paperTotalSum, setPaperTotalSum] = useState(0);
  const [patentTotalSum, setPatentTotalSum] = useState(0);
  const [grantTotalSum, setGrantTotalSum] = useState(0);
  useEffect(() => {
    setPaperTotalSum(sumPaperTotals(transformedData.Paper));
    setPatentTotalSum(sumPaperTotals(transformedData.Patent));
    setGrantTotalSum(sumPaperTotals(transformedData.Grant));
  }, [transformedData.Paper, sumForSource]);
  const [recordedData, setRecordedData] = useState(null);
  const handleClick = (params) => {
    trackClicksEvent("TRA", "Bar Clicked", "Chart Bar Clicked");

    const newOption = { ...option };
    const clickedDateRange = timeRanges[params.dataIndex];

    if (lastClicked && lastClicked.dataIndex === params.dataIndex) {
      // Revert colors to original state
      newOption.series.forEach((series, index) => {
        series.itemStyle = { color: originalColors[index] };
      });
      setLastClicked(null);
      setRecordedData(null); // Clear recordedData
    } else {
      newOption.series.forEach((series, index) => {
        series.itemStyle = {
          color: (item) => {
            const idx = item.dataIndex;
            if (idx === params.dataIndex) {
              return originalColors[index];
            } else {
              return "gray";
            }
          },
        };
      });

      // Find the data related to the clicked date range
      const relatedData = bar[params.dataIndex];
      setRecordedData(relatedData); // Set the related data for the table

      setLastClicked({
        seriesName: params.seriesName,
        dataIndex: params.dataIndex,
      });
    }

    echartsRef.current.getEchartsInstance().setOption(newOption, true);
  };
  const handleChange = (event) => {
    const newValue = event.target.value;
    ReactGA.event({
      category: "TRA Selection",
      action: "Period and Data Type Changed",
      label: newValue,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_V2}/v1/technology/readiness/chart`,
          {
            params: {
              period: period,
              phrases: keywords,
            },
            headers: headers,
          }
        );
        const sortedTimeRanges = response.data.time_ranges.map((range) => ({
          start: range[0],
          end: range[1],
        }));
        setBar(response.data.values);
        setTimeRanges(sortedTimeRanges);
        setDateInterval({
          start: sortedTimeRanges[0].start,
          end: sortedTimeRanges[sortedTimeRanges.length - 1].end,
        });
      } catch (error) {
        console.error("Error fetching keywords data:", error);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    if (searchTerm) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [searchTerm, period]);
  const transformRecordedData = (data) => {
    const transformed = {
      Paper: [],
      Grant: [],
      Patent: [],
      Enterprise: [],
      Forum: [],
    };

    data.forEach((item) => {
      transformed[item.type].push({
        source: item.key,
        total: item.doc_count,
      });
    });

    return transformed;
  };
  return (
    <div className="white-background">
      <Navbar
        openModal={() => {
          setOpenModal(true);
          setOptions(searchHistory)
          trackClicksEvent("TRA", "Modal Open", "TRA Modal Opened");
        }}
      />

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setOptions([]);
        }}
        aria-labelledby="search-modal"
        aria-describedby="search-functionality"
        align="right"
        sx={{
          position: "absolute",
          top: "70px",
          right: "30px",
        }}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "420px",
            height: "220px",
            borderRadius: "8px",
            padding: "10px",
            outline: "none",
            textAlign: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            id="search-modal"
            align="left"
            style={{
              paddingLeft: "13px",
              paddingBottom: "20px",
              fontFamily: "Glory",
              fontWeight: "700",
            }}
          >
            Search
          </Typography>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="text primary button group"
          >
            <Button
              onClick={() => setSelectedButton("TechScouting")}
              style={
                selectedButton === "TechScouting"
                  ? {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Scouting
            </Button>
            <Button
              onClick={() => setSelectedButton("TechReadiness")}
              style={
                selectedButton === "TechReadiness"
                  ? {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Readiness Assessment
            </Button>
          </ButtonGroup>

          <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option.Abbv
                ? `${option.Abbv} - ${option.Phrase}`
                : option.Phrase
            } 
            filterOptions={(options) => options}
            onInputChange={(event, newInputValue) =>
              handleInputChange(newInputValue)
            }
            onFocus={handleInputFocus}
            onChange={handleOptionChange}
            onKeyDown={handleKeyPress}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  selectedButton === "TechReadiness"
                    ? "Enter a specific technology to assess technology maturity"
                    : "Enter one or more fields of interest, separated by ,"
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  style: {
                    width: "400px",
                    borderRadius: "0 0 4px 4px",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    fontFamily: "Glory",
                    marginLeft: "10px",
                  },
                }}
              />
            )}
          />

          <Button
            variant="contained"
            style={{
              width: "400px",
              height: "48px",
              marginTop: "30px",
              borderRadius: "4px",
              backgroundColor: "#435452",
              color: "white",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Modal>
      <div className="svg-with-search">
        <NewSVG className="background-svg" />
        <div className="tech-title-2">Tech readiness assessment</div>
        <div className="container2">
          <div className="tech-search-bar2">
            <IconButton>
              <SearchIcon className="search-icon" style={{ color: "white" }} />
            </IconButton>
            <Autocomplete
            freeSolo
            options={options}
            loading={loading}
            fullWidth
            value={searchValue}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option.Abbv
                ? `${option.Abbv} - ${option.Phrase}`
                : option.Phrase
            } 
            filterOptions={(options) => options}
            onFocus={handleInputFocus}           
            onInputChange={(event, newInputValue) =>
              handleInputChange(newInputValue)
            }
            onChange={handleOptionChange}
            renderInput={(params) => (
              <InputBase
                {...params.InputProps}
                fullWidth
                placeholder="Enter one or more fields of interest, separated by ,"
                value={searchValue}
                onKeyDown={handleKeyPress}
                inputProps={{
                  ...params.inputProps,
                  style: { color: "white" },
                }}
                sx={{
                  ".MuiAutocomplete-clearIndicator": {
                    color: "white",
                  },
                }}
                endAdornment={
                  <>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        sx={{
                          color: "white",
                        }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                }
              />
            )}
          />
          </div>
        </div>
      </div>

      {/* Main Content Layout */}
      <Container maxWidth="lg" sx={{ py: "2rem" }}>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <TriCard phrase={keywords}></TriCard>
          </Grid>
          <Grid item xs={8}>
            <TriVerbal
              phrase={keywords}
              setShow={setShow}
              show={show}
            ></TriVerbal>
          </Grid>
        </Grid>
      </Container>
      {show && (
        <>
          <div
            className="period-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "3% 10%",
            }}
          >
            <div
              className="title"
              style={{
                color: "#435452",
                marginLeft: "10px",
                fontFamily: "Glory",
                fontSize: "34px",
                fontWeight: 600,
              }}
            >
              Data detail
            </div>

            <div>
              <FormControl
                style={{
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                <Select
                  value={period} // This only tracks the period. You might want to have a complex state object if you want to display multiple selections.
                  displayEmpty
                  renderValue={() => "PERIOD & DATA TYPE"}
                  onChange={handleChange}
                  style={{ color: "#435452" }}
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: ".875rem",
                    width: 201,
                    height: 36,
                    borderRadius: "4px",
                    border: "1px solid #000000",
                    gap: "8px",
                    "& .MuiSelect-icon": {
                      color: "#435452",
                    },
                  }}
                >
                  <MenuItem
                    value=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      <span className="period-text">Period </span>
                      <span className="ago-text">ago from now</span>
                    </div>

                    <RadioGroup value={period} onChange={handlePeriodChange}>
                      <FormControlLabel
                        value="90"
                        control={<Radio onClick={stopPropagation} />}
                        label="90 days"
                      />
                      <FormControlLabel
                        value="180"
                        control={<Radio onClick={stopPropagation} />}
                        label="180 days"
                      />
                      <FormControlLabel
                        value="365"
                        control={<Radio onClick={stopPropagation} />}
                        label="1 year"
                      />
                      <FormControlLabel
                        value="730"
                        control={<Radio onClick={stopPropagation} />}
                        label="2 years"
                      />
                    </RadioGroup>
                    <div
                      className="period-text"
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      Data type
                    </div>
                    <FormControl
                      component="fieldset"
                      style={{ alignItems: "flex-start" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dataType.paper}
                            onChange={handleDataTypeChange}
                            name="paper"
                            disabled={true}
                          />
                        }
                        label="Paper"
                        onClick={stopPropagation}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dataType.grant}
                            onChange={handleDataTypeChange}
                            name="Grant"
                            disabled={true}
                          />
                        }
                        label="Grant"
                        onClick={stopPropagation}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dataType.patent}
                            onChange={handleDataTypeChange}
                            name="patent"
                            disabled={true}
                          />
                        }
                        label="Patent"
                        onClick={stopPropagation}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dataType.enterprise}
                            onChange={handleDataTypeChange}
                            name="enterprise"
                            disabled={true}
                          />
                        }
                        label="Enterprise"
                        onClick={stopPropagation}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dataType.forum}
                            onChange={handleDataTypeChange}
                            name="forum"
                            disabled={true}
                          />
                        }
                        label="Forum"
                        onClick={stopPropagation}
                      />
                    </FormControl>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="parent-container">
            <ReactEcharts
              ref={echartsRef}
              option={option}
              style={{
                width: "80%",
                height: "548px",
              }}
              onEvents={{
                click: handleClick,
              }}
            />
          </div>
          <div className="flex-container">
            <TableContainer
              component={Paper}
              style={{
                width: "80%",
                height: "502px",
                borderRadius: "10px",
                margin: "1%",
                top: "948px",
                left: "20%",
              }}
              elevation={3}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5%" }}></TableCell>
                    <TableCell style={{ width: "60%" }}>Type</TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "20%", paddingLeft: "1.5%" }}
                    >
                      Number of Articles
                    </TableCell>
                    <TableCell style={{ width: "8%" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(data).map((type) => (
                    <React.Fragment key={type}>
                      <TableRow
                        style={{ cursor: "pointer" }}
                        onClick={() => handleToggle(type)}
                      >
                        <TableCell>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: getColor(type),
                            }}
                          ></div>
                        </TableCell>
                        <TableCell style={{ width: "60%" }}>{type}</TableCell>
                        <TableCell align="center" style={{ width: "20%" }}>
                          {type === "Paper"
                            ? recordedData === null
                              ? paperTotalSum
                              : sumPaperTotals(
                                  transformRecordedData(recordedData).Paper
                                )
                            : type === "Patent"
                            ? recordedData === null
                              ? patentTotalSum
                              : sumPaperTotals(
                                  transformRecordedData(recordedData).Patent
                                )
                            : type === "Grant"
                            ? recordedData === null
                              ? grantTotalSum
                              : sumPaperTotals(
                                  transformRecordedData(recordedData).Grant
                                )
                            : 0}
                        </TableCell>
                        <TableCell align="right" style={{ width: "8%" }}>
                          <IconButton size="small">
                            {openRow === type ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            background: "#F4F4F4",
                            borderBottom: "none",
                            width: "5%", // Adjusted width
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            background: "#F4F4F4",
                            borderBottom: "none",
                            width: "60%", // Adjusted width
                          }}
                          colSpan={3}
                        >
                          <Collapse
                            in={openRow === type}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Paper
                              style={{
                                background: "#F4F4F4",
                                boxShadow: "0px 0px 0px 0px #0000001A",
                                marginBottom: "8px",
                                borderBottom: "none",
                              }}
                            >
                              <Table
                                style={{
                                  border: "none",
                                  borderBottom: "none",
                                  tableLayout: "fixed", // Ensures consistent column widths
                                  width: "100%", // Matches parent width
                                }}
                              >
                                <TableBody
                                  style={{
                                    background: "#F4F4F4",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    display: "block",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                  }}
                                >
                                  {(recordedData
                                    ? transformRecordedData(recordedData)[type]
                                    : transformedData[type]
                                  ).map((item, index) => (
                                    <TableRow
                                      key={index}
                                      style={{
                                        background: "#F4F4F4",
                                        borderBottom: "none",
                                        display: "table",
                                        tableLayout: "fixed",
                                        width: "100%", // Consistent width
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          width: "13%",
                                          background: "#F4F4F4",
                                          borderBottom: "none",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor:
                                              colors[index % colors.length],
                                          }}
                                        ></div>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "50%",
                                          color: "#1D2D37",
                                          fontFamily: "Glory",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          lineHeight: "16px",
                                          letterSpacing: "0em",
                                          background: "#F4F4F4",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {item.source}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          width: "20%",
                                          borderBottom: "none",
                                          color: "#1D2D37",
                                          fontFamily: "Glory",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          lineHeight: "16px",
                                          letterSpacing: "0em",
                                          background: "#F4F4F4",
                                        }}
                                      >
                                        <Link
                                          to={`/TechReadinessArticle?type=${type}&keyword=${searchTerm}&start=${getUnixTimestamp(
                                            dateInterval.start
                                          )}&end=${getUnixTimestamp(
                                            dateInterval.end
                                          )}&total=${item.total}&source=${
                                            item.source
                                          }`}
                                          style={{
                                            color: "#3D766F",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                          }}
                                          onClick={() => {
                                            ReactGA.event({
                                              category: "TRA Table",
                                              action:
                                                "Table Number of Articles Clicked",
                                            });
                                          }}
                                        >
                                          {item.total}
                                        </Link>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          borderBottom: "none",
                                          width: "5%",
                                        }}
                                      />
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Paper>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </div>
  );
}

export default TechReadiness;
