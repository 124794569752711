import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import ReactGA from 'react-ga4';
import { jwtDecode } from 'jwt-decode'
// Authentication utilities
const isAuthenticated = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
    try {
      const user = jwtDecode(token);
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      ReactGA.set({ user_id: user.hashed_id});
      return { authenticated: true, token: user.hashed_id }; // Return the decoded token too
    } catch (error) {
      console.error("Token decode error:", error);
      return { authenticated: false };
    }
  }
  return { authenticated: false };
};

const loginWithAlert = async () => {
  const username = prompt("Enter username:");
  const password = prompt("Enter password:");

  const data = new URLSearchParams();
  data.append("username", username);
  data.append("password", password);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_V2}/auth/token`,
      data
    );
    if (response.data && response.data.access_token) {
      localStorage.setItem("access_token", response.data.access_token);
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      ReactGA.set({ user_id: response.data.access_token });
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: response.data.access_token,
      });
      return true;
    } else {
      alert("Invalid credentials");
      return false;
    }
  } catch (error) {
    console.error("There was a problem with the axios request:", error);
    // Show specific error based on the error response
    if (error.response && error.response.status === 403) {
      alert("Account expired");
    } else {
      console.error("Error fetching keywords data:", error);
    }
    if (error.response) {
      if (error.response.status === 400) {
        return loginWithAlert();
      } 
      alert(`Error: ${error.response.status}`);
    } else {
      alert("Login failed");
    }
    return false;
  }
};

// PrivateWrapper component
function PrivateWrapper({ children }) {
  const authStatus = isAuthenticated();
  const [isAuth, setIsAuth] = useState(authStatus.authenticated);
  const [isLoading, setLoading] = useState(false);
  const [isChecking, setChecking] = useState(true); 

  useEffect(() => {
    const authStatus = isAuthenticated();
    if (isChecking && authStatus.authenticated) {
      setIsAuth(true);
      setChecking(false);
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      ReactGA.set({ user_id: authStatus.token });
      return;
    }

    if (isChecking && !authStatus.authenticated) {
      setChecking(false);
    }

    if (!isChecking && !isAuth && !isLoading) {
      setLoading(true);
      loginWithAlert().then(loggedIn => {
        setIsAuth(loggedIn);
        setLoading(false);
      });
    }
  }, [isAuth, isLoading, isChecking]);

  if (isAuth) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}

export default PrivateWrapper;