import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import ReactGA from "react-ga4";

function calculateLuminance(color) {
  const rgb = d3.color(color);
  if (!rgb) return 0;

  const luminance = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;
  return luminance;
}

// Determine the text color based on gradient luminance
function getTextColorForGradient(gradient) {
  const startLuminance = calculateLuminance(gradient.start);
  const endLuminance = calculateLuminance(gradient.end);

  // Calculate average luminance of the gradient
  const averageLuminance = (startLuminance + endLuminance) / 2;

  // If the average luminance is below a threshold, set text color to white; otherwise, set it to black
  return averageLuminance < 128 ? " #FFFFFF" : "#435452";
}

function BubbleChart({
  data,
  gradients,
  sortCriteria,
  selectedServices,
  period,
}) {
  const svgRef = useRef(null);
  const trackGoogleAnalyticsEvent = (category, action, label, value = "") => {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const svgBounds = svg.node().getBoundingClientRect();
    const width = svgBounds.width;
    const height = svgBounds.height;

    const rootNode = d3
      .hierarchy({ children: data })
      .sum((d) => Math.abs(d.trend_change));

    const pack = d3.pack().size([width, height]).padding(5);

    let nodes = pack(rootNode).descendants().slice(1);
    const sizes = [115, 110, 105, 100, 90, 80, 90, 90, 90, 85]; // sizes for the top 10 nodes

    nodes.forEach((node, index) => {
      const angle = (index / nodes.length) * 2 * Math.PI;
      const distance = 300; // Adjust the distance as needed
      node.x = width / 2 + distance * Math.cos(angle);
      node.y = height / 2 + distance * Math.sin(angle);
      node.r = sizes[index];
    });

    const simulation = d3
      .forceSimulation(nodes)
      .force("collide", d3.forceCollide((d) => d.r + 1).strength(1))
      .force(
        "x",
        d3
          .forceX((d) =>
            d.x < (1.2 * width) / 2 ? (1.5 * width) / 4 : (1.1 * width) / 2
          )
          .strength(0.005)
      )
      .force("y", d3.forceY((d) => (1.3 * height) / 3).strength(0.07)) // Here's the change, from height / 2 to height / 3
      .on("tick", ticked);

    const drag = d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);

    const bubbleGroup = svg
      .selectAll("g")
      .data(nodes)
      .join("g")
      .attr("transform", (d) => `translate(${d.x},${d.y})`)
      .call(drag);

    const circle = bubbleGroup
      .append("circle")
      .attr("r", (d) => d.r)
      .attr("fill", (d, i) => `url(#gradient${i % gradients.length})`)
      .style("cursor", "pointer")
      .on("mouseover", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", d.r + 30);
      })
      .on("mouseout", function (event, d) {
        d3.select(this).transition().duration(200).attr("r", d.r);
      })
      .on("click", function (event, d) {
        const keyword = encodeURIComponent(d.data.name);
        const cluster =  encodeURIComponent(d.data.label);
        const url = `/KeywordList?cluster=${cluster}&keyword=${keyword}&services=${selectedServices
          .map((s) => s.replace(/&/g, "and"))
          .join(",")}&period=${period}`;
        trackGoogleAnalyticsEvent(
          "BubbleChart",
          "click",
          `Keyword: ${keyword}, Services: ${selectedServices}`
        );
        window.open(url, "_blank");
      });
    circle.each(function (d) {
      if (d.hasEllipsis) {
        d3.select(this).append("title").text(d.data.name);
      }
    });

    function wrap(d) {
      var text = d3.select(this),
        width = d.r * 2,
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 2,
        maxLines = 3,
        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", 0);
      d.hasEllipsis = false;
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("dy", `${lineHeight}em`)
            .text(word);

          lineNumber++;
          if (lineNumber === maxLines) {
            while (tspan.node().getComputedTextLength() + 10 > width && word) {
              word = line.pop();
              tspan.text(line.join(" ") + "...");
              d.hasEllipsis = true;
            }
            break;
          }
        }
      }

      const numLines = text.selectAll("tspan").nodes().length;
      const offset = -((lineHeight / 2) * (numLines - 1));
      lineNumber = 0;
      text
        .selectAll("tspan")
        .attr("dy", (d) => `${offset + lineHeight * lineNumber++}em`);
    }

    bubbleGroup
      .append("text")
      .attr("dy", "-0.3em") // Adjust text position
      .style("text-anchor", "middle") // Make text center-aligned
      .text((d) => `${d.data.name}`)
      .attr("fill", "white")
      .style("font-family", "Glory")
      .style("fill", (d, i) =>
        getTextColorForGradient(gradients[i % gradients.length])
      )
      .style("font-family", "Glory")
      .style("font-size", "13px")
      .style("font-weight", "700")
      .style("line-height", "29px")
      .style("text-align", "center")
      .each(wrap);
    // Add an arrow pointing upward
    if (sortCriteria !== "relevantKeyword") {
      bubbleGroup
        .append("text")
        .attr("dx", "-1.6em") // Adjust arrow position to the left
        .attr("dy", "1.3em") // Adjust arrow position
        .style("text-anchor", "middle") // Make arrow center-aligned
        .text((d) => (d.data.trend_change < 0 ? "↓" : "↑")) // Choose the arrow based on the trend change
        .style("fill", (d) => (d.data.trend_change < 0 ? "red" : "green")) // Choose color based on trend change
        .style("font-size", "20px");
    }

    bubbleGroup
      .append("text")
      .attr("dy", "1.3em")
      .style("text-anchor", "middle")
      .text((d) => {
        if (sortCriteria === "relevantKeyword") {
          return `${"(" + d.data.n_keywords + ")"}`; // Display n_keyword if sorting option is "keyword"
        } else {
          return `${Math.abs((d.data.trend_change * 100).toFixed(0))}%`; // Otherwise, display trend change percentage
        }
      })
      .style("fill", (d, i) =>
        getTextColorForGradient(gradients[i % gradients.length])
      ) // Font color based on gradient
      .style("font-family", "Glory")
      .style("font-size", "18px")
      .style("font-weight", "700")
      .style("line-height", "29px")
      .style("letter-spacing", "0.01em")
      .style("text-align", "center");

    // Define gradients in the <defs> section
    const defs = svg.append("defs");

    gradients.forEach((gradient, i) => {
      const gradientDef = defs
        .append("linearGradient")
        .attr("id", `gradient${i}`);

      gradientDef
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", gradient.start);

      gradientDef
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", gradient.end);
    });

    function ticked() {
      bubbleGroup.attr("transform", (d) => {
        // Constraints for the nodes so they don't go outside the SVG
        const radius = d.r;
        const maxX = width - radius;
        const maxY = height - radius;
        const minX = radius;
        const minY = radius;
        d.x = Math.max(Math.min(d.x, maxX), minX);
        d.y = Math.max(Math.min(d.y, maxY), minY);

        return `translate(${d.x},${d.y})`;
      });
    }

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
    // eslint-disable-next-line
  }, [data, gradients]);

  return <svg ref={svgRef} width="100%" height="600px"></svg>;
}

export default BubbleChart;
