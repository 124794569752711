import React, { useState, useCallback, useEffect, useRef } from "react";
import { ReactComponent as LogoSVG } from "../assets/svg/logo6.svg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear'; 
import { useNavigate, Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "../App.css";
import ReactGA from "react-ga4";
import useAnalyticsPageTracker from '../hooks/useAnalyticsPageTracker';
import { Typography, InputAdornment } from "@mui/material";
import { debounce } from 'lodash';

function TechScouting() {
  useAnalyticsPageTracker();
  
  const [selectedButton, setSelectedButton] = useState("TechScouting");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const navigate = useNavigate();

  const latestInputValueRef = useRef("");

  // Fetch search history on mount
  useEffect(() => {
    const fetchSearchHistory = async () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_V2}/v1/search_history`,
          { headers }
        );
        setSearchHistory(response.data.results);
      } catch (error) {
        console.error("Error fetching search history:", error);
      }
    };

    fetchSearchHistory();
  }, []); 

  // Handler for input changes
  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    latestInputValueRef.current = newInputValue;

    if (!newInputValue) {
      // Cancel any pending debounced calls
      debouncedFetchAutocompleteOptions.cancel();
      // Reset options to search history
      setOptions(searchHistory);
      setValue(null);
    } else {
      // Fetch autocomplete options when input is not empty
      debouncedFetchAutocompleteOptions(newInputValue);
    }
  };

  // Handle input focus (to show search history when input is focused)
  const handleInputFocus = () => {
    if (!searchTerm) {
      setOptions(searchHistory);
    }
  };

  const handleOptionChange = (event, newValue) => {
    setValue(newValue);
    let termToSearch = '';

    if (typeof newValue === 'object' && newValue !== null) {
      termToSearch = newValue.Abbv || newValue.Phrase;
      setSearchTerm(termToSearch);
    } else if (typeof newValue === 'string') {
      termToSearch = newValue;
      setSearchTerm(newValue);
    }

    if (termToSearch) {
      ReactGA.event("search_performed", {
        category: "Autocomplete Option Clicked",
        label: "Autocomplete Clicked",
        search_term: termToSearch,
      });
      if (selectedButton === "TechScouting") {
        navigate(`/TechScoutingResult?keywords=${encodeURIComponent(termToSearch)}&activity=search`);
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(termToSearch)}&activity=search`);
      }
    } else {
      setSearchTerm('');
    }
  };

  const fetchAutocompleteOptions = async (prefix) => {
    if (!prefix) return;
    setLoading(true);

    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v1/autocomplete`,
        {
          headers,
          params: { prefix: prefix },
        }
      );
      console.log(prefix , latestInputValueRef.current)
      // Only update options if the input value hasn't changed
      if (prefix === latestInputValueRef.current) {
        setOptions(response.data.results);
      }
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchAutocompleteOptions = useCallback(
    debounce((newInputValue) => fetchAutocompleteOptions(newInputValue), 300),
    []
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // const termToSearch = value && typeof value === 'object' ? (value.Abbv || value.Phrase) : searchTerm;

      const category = selectedButton;
      const event_name = "search_performed";

      ReactGA.event(event_name, {
        category,
        label: "Search Term",
        search_term: searchTerm,
      });

      if (selectedButton === "TechScouting") {
        navigate(
          `/TechScoutingResult?keywords=${encodeURIComponent(searchTerm)}&activity=search`
        );
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(searchTerm)}&activity=search`);
      }
    }
  };

  const handleTechScoutingClick = () => {
    setSelectedButton("TechScouting");
  };

  const handleTechReadinessClick = () => {
    setSelectedButton("TechReadiness");
  };

  return (
    <div className="container" sx={{ marginTop: "100px" }}>
      <div className="epic2-background">
        <Link to="/">
          <LogoSVG className="logo" />
        </Link>
        <div className="content-wrapper">
          <div className="sentence">
            Navigate the future of technology, <br />
            Today.
          </div>

          <div className="button-group">
            <Button
              variant="contained"
              className={selectedButton === "TechScouting" ? "active" : ""}
              onClick={handleTechScoutingClick}
              style={{ padding: "0" }}
            >
              Tech Scouting
            </Button>
            <Button
              variant="contained"
              className={selectedButton === "TechReadiness" ? "active" : ""}
              onClick={handleTechReadinessClick}
            >
              Tech Readiness Assessing
            </Button>
          </div>
          
          <div className="search-bar">
            <Autocomplete
              freeSolo
              options={options}
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : option.Abbv
                  ? `${option.Abbv} - ${option.Phrase}`
                  : option.Phrase || "No label"
              } 
              filterOptions={(options) => options}
              onInputChange={handleInputChange}
              onFocus={handleInputFocus}
              onChange={handleOptionChange}
              loading={loading}
              value={value}
              clearIcon={<ClearIcon style={{ color: 'white' }} />}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body1" style={{ fontWeight: "bold", marginRight: "10px" }}>
                    {option.Abbv || option.Phrase || option}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {option.Phrase || ''}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  placeholder={
                    selectedButton === "TechReadiness"
                      ? "Enter a specific technology to assess technology maturity"
                      : "Enter one or more fields of interest, separated by ,"
                  }
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="search-icon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    sx: {
                      width: "550px", 
                      borderRadius: "4px 0px 0px 0px",
                      opacity: 0.8,
                      backgroundColor: "#415250",
                      color: "#FFFFFF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent", 
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                    color: 'white',
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechScouting;
