import {
  Popper,
  Box,
  Fade,
  Stack,
  Typography,
  Card,
  ClickAwayListener,
} from "@mui/material";
import React from "react";

export default function TriCardPBPopper({
  isOpen,
  anchorEl,
  clickAwayHandler,
}) {
  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Popper open={isOpen} anchorEl={anchorEl} placement="right" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Card
              elevation={1}
              sx={{
                p: "1rem",
                backgroundColor: "#F8F8F8",
                maxWidth: "430px",
                maxHeight: "500px",
                overflow: "auto",
              }}
            >
              <Stack spacing={2}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      color: "#435452",
                    }}
                  >
                    The Technology Readiness Index , ranging from 0-100, is
                    calculated through an advanced AI algorithm. It analyzes
                    diverse data sources corresponding to different stages of
                    technology development. This includes academic papers and
                    articles in early stages, patents in mid-stages, and
                    corporate whitepapers and online forums in later stages. The
                    index is determined by the frequency of technology mentions
                    in these categories, weighted appropriately and adjusted for
                    standard deviation, providing a reliable and insightful
                    measure of technological maturity.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#E78686",
                    }}
                  >
                    0-20: Research Stage
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      color: "#435452",
                    }}
                  >
                    This range signifies that the technology is primarily in the
                    academic research phase, with limited publications and
                    conceptual validation. It's largely unexplored in patents
                    and company reports.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#F2B379",
                    }}
                  >
                    21-40: Prototyping Stage
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      color: "#435452",
                    }}
                  >
                    The technology has some academic backing and may have
                    initial prototypes. It's beginning to appear in patent
                    databases but is not yet widely adopted in industry reports
                    or forums.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#FBDA2C",
                    }}
                  >
                    41-60: Pilot Stage
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      color: "#435452",
                    }}
                  >
                    The technology has moved beyond the lab and is in the pilot
                    or beta testing phase. It has some market validation, often
                    cited in academic journals, patents, and specialized forums
                    but may lack broad industry reports.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#65CC9B",
                    }}
                  >
                    61-80: Adoption Stage
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      color: "#435452",
                    }}
                  >
                    The technology is well-documented in academic journals,
                    patents, and company reports. It has been adopted by some
                    industry players and discussed in technology forums,
                    indicating a level of market acceptance.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#69A6DE",
                    }}
                  >
                    81-100: Market Leadership Stage
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Glory", sans-serif',
                      fontSize: "0.875rem",
                      color: "#435452",
                    }}
                  >
                    The technology is a standard topic in academic journals,
                    heavily patented, and frequently featured in industry
                    reports and technology forums. It's widely adopted and holds
                    a leading position in the market.
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
