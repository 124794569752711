// src/styles.js
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const StyledAppBar = styled(AppBar)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  paddingTop: "15px",
});


// src/styles.js
export const StyledButton = styled(Button)(({ theme }) => ({
  height: 30,
  whiteSpace: 'nowrap',
  flex: '1 1 auto',
  [theme.breakpoints.down('xs')]: {
    minWidth: 'unset',
    padding: theme.spacing(1),
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Glory, sans-serif',
  fontWeight: 'bold',
  letterSpacing: "0.01em",
  textAlign: "center",
  color: '#778383', // Default color
  fontSize: '18px',
  lineHeight: "30px",
  paddingLeft: '10px',
  '&.selected': {
    fontSize: '25px',
    lineHeight: "34px",
    color: '#83D3CA', // Selected color
  },
}));


export const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});
