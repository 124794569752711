import React from "react";
import { Box, Typography } from "@mui/material";

export default function TriVerbalSection({ title, content }) {
  return (
    <Box>
      <Box
        sx={{
          borderLeft: "4px solid #3D766F",
        }}
      >
        <Typography
          component={"h6"}
          sx={{
            fontFamily: '"Glory", sans-serif',
            fontWeight: "500",
            fontSize: "1.25rem",
            ml: ".5rem",
            color: "#3D766F",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ borderLeft: "4px solid transparent", mt: ".75rem" }}>
        <Typography
          sx={{
            ml: ".5rem",
            color: "#435452",
            fontFamily: '"Glory", sans-serif',
          }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
}
