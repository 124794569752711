import React, { useState, useEffect, useRef } from "react";
import Menu from "@mui/material/Menu";
import {
  ThemeProvider,
  createTheme,
  Box,
  Button,
  Typography,
  ButtonGroup,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TuneIcon from "@mui/icons-material/Tune";
import IconButton from "@mui/material/IconButton";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../App.css";
import {
  StyledAppBar,
  StyledButton,
  StyledTypography,
  StyledToolbar,
} from "../styles";
import clsx from "clsx";
import { ReactComponent as Logo } from "../assets/svg/logo5.svg";
import SettingsDialog from "../components/SettingDialog";
import Loading from "./Loading";
import BubbleChart from "./Bubble";
import TechScouting from "./TechScouting";
import DataTable from "./DataTable";
import axios from "axios";
import ReactGA from "react-ga4";
import useAnalyticsPageTracker from "../hooks/useAnalyticsPageTracker";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const menuItems = [
  "Financial Services",
  "Biotechnology",
  "Software & IT Services",
  "Telecommunications",
  "Electronic Hardware & Equipment",
  "Chemical",
  "Energy & Renewable Energy",
  "Power & Electricity",
  "Metals & Mining",
  "Aerospace & Defense",
  "Machinery Manufacturing",
  "Automotive",
  "Consumer Electronics",
  "Medical Research",
  "Healthcare Services",
  "Pharmaceutical",
  "Semiconductor",
  "Advanced Materials & Applications",
];
const serviceItems = [
  "Software & IT Services",
  "Financial Services",
  "Biotechnology",
  "Telecommunications",
  "Electronic Hardware & Equipment",
  "Chemical",
  "Energy & Renewable Energy",
  "Power & Electricity",
  "Metals & Mining",
  "Aerospace & Defense",
  "Machinery Manufacturing",
  "Automotive",
  "Consumer Electronics",
  "Medical Research",
  "Healthcare Services",
  "Pharmaceutical",
  "Semiconductor",
  "Advanced Materials & Applications",
];

const gradients = [
  { start: "#3D766F", end: "rgba(53, 58, 58, 0.5)" },
  { start: "#83D3CA", end: "rgba(131, 211, 202, 0.5)" },
  { start: "#ADDAE9", end: "#62B5D1" },
  { start: "#70DFD8", end: "#52A9A4" },
];

function HomePage() {
  useAnalyticsPageTracker();
  const trackGoogleAnalyticsEvent = (category, action, label, value = "") => {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(
    "Software & IT Services"
  );
  const [menuOrder, setMenuOrder] = React.useState(menuItems);
  const [selectedServices, setSelectedServices] = React.useState([
    selectedItem,
  ]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const mainRef = React.useRef(null);
  const [selectedView, setSelectedView] = React.useState("bubble");
  const [showScrollUp, setShowScrollUp] = React.useState(false);
  const [lastScrollY, setLastScrollY] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tempSortCriteria, setTempSortCriteria] = useState("percentChanged");
  const [tempSelectedServices, setTempSelectedServices] = useState([
    selectedItem,
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
        const decodedToken = jwtDecode(token);
        const isExpired = decodedToken.exp * 1000 < new Date().getTime();
        if (isExpired) {
          navigate('/Login?reset=accountExpired');
        }
    }
  }, [navigate]);
  const openDialog = () => {
    trackGoogleAnalyticsEvent(
      "Homepage",
      "Modal Open",
      "Homepage Modal Opened"
    );
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    sendServiceRequest();
    if (tempSortCriteria) {
      setSortCriteria(tempSortCriteria);
    }
  };
  const cancelDialog = () => {
    setIsDialogOpen(false);
  };
  const getMenuItemLabel = () => {
    const count = selectedServices.length;
    if (count === 0) return selectedItem;
    if (count === 1) return selectedItem;
    return `${count} industries selected`;
  };
  const toggleServiceSelection = (service) => {
    if (tempSelectedServices.includes(service)) {
      setTempSelectedServices((prev) => {
        const updatedServices = prev.filter((s) => s !== service);
        return updatedServices;
      });
    } else {
      setTempSelectedServices((prev) => {
        const updatedServices = [...prev, service];
        return updatedServices;
      });
    }
  };
  const confirmServiceSelection = () => {
    setAnchorEl(null);
    setSelectedServices(tempSelectedServices);
    const selectedServicesString = tempSelectedServices.join(", ");
    trackGoogleAnalyticsEvent(
      "Service Selection",
      "Confirm",
      selectedServicesString
    );
  };

  let cancelTokenSources = [];
  const sendServiceRequest = async () => {
    setAnchorEl(null);
    if (selectedServices.length === 1) {
      const selectedIndex = menuItems.indexOf(selectedServices[0]);
      let newMenuOrder = [];
      for (let offset = -2; offset <= 2; offset++) {
        const currentIndex =
          (selectedIndex + offset + menuItems.length) % menuItems.length;
        newMenuOrder.push(menuItems[currentIndex]);
      }
      setMenuOrder(newMenuOrder);
      setSelectedItem(selectedServices[0]);
    }
    setIsLoading(true);
    const formattedServices = selectedServices.map((s) =>
      s.replace(/&/g, "and")
    );
    cancelTokenSources.forEach((source) => {
      source.cancel("Canceled due to new request");
    });
    cancelTokenSources = [];
    const industriesParam = formattedServices.join(",");
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v1/technology/concepts?industries=${industriesParam}&period=${period}`,
        { headers }
      );
      setData(response.data.clusters.slice(0, 10));
      setOriginalData(response.data.clusters);
      setTotal(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 403) {
        localStorage.removeItem("access_token");
        alert("Please Login Again.");
        window.location.reload();
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleClose = (event) => {
    setAnchorEl(null);

    if (event) {
      event.stopPropagation();
    }
  };

  const handleScrollToMain = () => {
    if (mainRef.current) {
      ReactGA.event({
        category: "Scroll Down Clicked",
        action: "Click",
      });
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY) {
        // This indicates that the user is scrolling upwards
        setShowScrollUp(false);
      } else if (currentScrollY > 20) {
        // The user is scrolling downwards and is past the threshold
        setShowScrollUp(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  // const [startIndex, setStartIndex] = useState(0);
  const handleMenuItemClick = (menuItem, index) => {
    if (menuItem !== selectedItem) {
      setSelectedItem(menuItem);

      if (serviceItems.includes(menuItem)) {
        setTempSelectedServices([menuItem]);
        setSelectedServices([menuItem]);
        trackGoogleAnalyticsEvent("Service Selection", "Top Row", menuItem);
      } else {
        setSelectedServices([]);
        setTempSelectedServices([]);
      }

      setAnchorEl(null);
    }

    let newMenuOrder = [];
    for (let offset = -2; offset <= 2; offset++) {
      const currentIndex =
        (index + offset + menuItems.length) % menuItems.length;
      newMenuOrder.push(menuItems[currentIndex]);
    }

    setMenuOrder(newMenuOrder);
  };

  const handleClick = (menuItem, event) => {
    const indexInVisibleItems = visibleMenuItems.indexOf(menuItem);

    if (indexInVisibleItems === 2) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
    const index = menuItems.indexOf(menuItem);
    handleMenuItemClick(menuItem, index);
  };

  const visibleMenuItems = menuOrder.slice(0, 0 + 5);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [total, setTotal] = useState(0);
  const [period, setPeriod] = useState("365");
  const [customDays, setCustomDays] = useState("");
  const [sortCriteria, setSortCriteria] = useState("percentChanged");
  const [regionCountryMap, setRegionCountryMap] = useState({});
  const today = new Date();
  const daysToSubtract = customDays !== "" ? parseInt(customDays) : period;
  const lookbackDate = new Date(today);
  lookbackDate.setDate(today.getDate() - daysToSubtract);
  useEffect(() => {
    if (originalData) {
      const sortedData = [...originalData].sort((a, b) => {
        if (sortCriteria === "relevantKeyword") {
          return b.n_keywords - a.n_keywords;
        } else if (sortCriteria === "percentChanged") {
          return b.trend_change - a.trend_change;
        }
        return 0;
      });
      setData(sortedData.slice(0, 10));
    }
  }, [originalData, sortCriteria]);
  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
    if (event.target.value !== "other") {
      setCustomDays("");
    }
    trackGoogleAnalyticsEvent(
      "Homepage",
      "Modal Period Change",
      event.target.value
    );
  };
  const handleSortCriteriaChange = (event) => {
    setTempSortCriteria(event.target.value);
    trackGoogleAnalyticsEvent(
      "Homepage",
      "Modal Sort Criteria Change",
      event.target.value
    );
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    return `${year}-${month}`;
  };

  const formattedLookbackDate = formatDate(lookbackDate);
  const firstRender = useRef(true);
  useEffect(() => {
    // If it's the first render, just return.
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!anchorEl) {
      sendServiceRequest();
    }
  }, [selectedServices]);
  // ===================
  useEffect(() => {
    const fetchData = async () => {
      const industry = encodeURIComponent("Software and IT Services");
      const period = 365;
      setIsLoading(true);

      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_V2}/v1/technology/concepts?industries=${industry}&period=${period}`,
          { headers }
        );
        setData(response.data.clusters.slice(0, 10));
        setOriginalData(response.data.clusters);
        setTotal(response.data.total);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("access_token");
          alert("Please Login Again");
          window.location.reload();
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="App">
      <StyledAppBar position="static">
        <div className="logo-container">
          <Logo style={{ height: 50 }} />
          <hr className="separator" />
        </div>
        <StyledToolbar>
          {visibleMenuItems.map((menuItem, index) => (
            <div
              key={menuItem}
              onClick={(e) => handleClick(menuItem, e)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100vw",
              }}
            >
              <StyledButton>
                <StyledTypography
                  variant="h5"
                  className={clsx({ selected: menuItem === selectedItem })}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  {menuItem === selectedItem ? getMenuItemLabel() : menuItem}
                  {menuItem === selectedItem && (
                    <ArrowDropDownIcon style={{ verticalAlign: "middle" }} />
                  )}
                </StyledTypography>
              </StyledButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "100vw",
                    height: "532px",
                    marginTop: "96px",
                    backgroundColor: "#202323",
                    opacity: 0.3, // Set opacity to desired level
                  },
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box
                  sx={{
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      paddingLeft: 2,
                      fontFamily: "Glory, sans-serif",
                      fontWeight: "bold",
                      color: "white", // Set title font color to white
                    }}
                  >
                    Industries
                  </Typography>

                  {serviceItems.map((service) => (
                    <Button
                      key={service}
                      variant="outlined"
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleServiceSelection(service);
                      }}
                      sx={{
                        margin: 1.5,
                        borderRadius: "25px",
                        fontFamily: "Inter, sans-serif", // Set font to Inter
                        textTransform: "none", // Don't capitalize every letter
                        fontWeight: "bold",
                        padding: "8px 16px",
                        color: tempSelectedServices.includes(service)
                          ? "black"
                          : "white",
                        borderColor: tempSelectedServices.includes(service)
                          ? "transparent"
                          : "white", // Conditionally set border color
                        backgroundColor: tempSelectedServices.includes(service)
                          ? "#83D3CA"
                          : "transparent",
                        "&:hover": {
                          borderColor: tempSelectedServices.includes(service)
                            ? "transparent"
                            : "white", // Same as non-hover state
                        },
                      }}
                    >
                      {service}
                    </Button>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={(event) => {
                      event.stopPropagation();
                      confirmServiceSelection();
                    }}
                    sx={{
                      alignSelf: "center",
                      position: "absolute",
                      top: "120%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      marginBottom: 2,
                      borderColor: "#83D3CA",
                      color: "#83D3CA",
                      "&:hover": {
                        backgroundColor: "#83D3CA",
                        color: "white",
                      },
                    }}
                  >
                    FIND TOP TRENDS
                  </Button>
                </Box>
              </Menu>
            </div>
          ))}
        </StyledToolbar>
      </StyledAppBar>
      <div className="settings-row">
        <div
          style={{
            flex: 1,
            textAlign: "center",
            fontFamily: "Glory",
            fontWeight: 500,
            fontSize: "1.1rem",
          }}
        >
          <span style={{ fontWeight: 700, fontSize: "1.1rem" }}>
            {formattedLookbackDate} - now
          </span>{" "}
          | {Object.keys(regionCountryMap).length} Regions | {total} articles
          <TuneIcon
            onClick={openDialog}
            style={{ marginLeft: "10px", position: "relative", top: "5px" }}
          />
        </div>

        <ButtonGroup
          variant="outlined"
          style={{ borderColor: "#3D766F", paddingRight: "30px" }}
        >
          <Button
            onClick={() => {
              setSelectedView("bubble");
              trackGoogleAnalyticsEvent(
                "Homepage",
                "Bubble_view",
                "Bubble Button Clicked"
              );
            }}
            style={{
              width: "42px",
              height: "36px",
              borderColor: "#3D766F",
              color: "#3D766F",
              backgroundColor:
                selectedView === "bubble" ? "#3D766F" : "transparent",
            }}
          >
            <BubbleChartIcon
              style={{
                color: "#FFFFFF",
              }}
            />
          </Button>
          <Button
            onClick={() => {
              setSelectedView("list");
              trackGoogleAnalyticsEvent(
                "Homepage",
                "list_view",
                "List Button Clicked"
              );
            }}
            style={{
              width: "42px",
              height: "36px",
              borderColor: "#3D766F",
              color: "#3D766F",
              backgroundColor:
                selectedView === "list" ? "#3D766F" : "transparent",
            }}
          >
            <FormatListBulletedIcon style={{ color: "#FFFFFF" }} />
          </Button>
        </ButtonGroup>
      </div>
      <ThemeProvider theme={darkTheme}>
        <SettingsDialog
          regionCountryMap={regionCountryMap}
          period={period}
          setPeriod={setPeriod}
          isOpen={isDialogOpen}
          onOpen={openDialog}
          onClose={closeDialog}
          cancelDialog={cancelDialog}
          disableBackdropClick={true} // Add this line
          handlePeriodChange={handlePeriodChange}
          customDays={customDays}
          setCustomDays={setCustomDays}
          sortCriteria={tempSortCriteria}
          setSortCriteria={setSortCriteria}
          handleSortCriteriaChange={handleSortCriteriaChange}
        />
        <div className="flex-container">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {selectedView === "bubble" && (
                <BubbleChart
                  data={data}
                  gradients={gradients}
                  sortCriteria={sortCriteria}
                  selectedServices={selectedServices}
                  period={period}
                />
              )}
              {selectedView === "list" && (
                <DataTable
                  data={originalData}
                  sortCriteria={sortCriteria}
                  selectedServices={selectedServices}
                  period={period}
                />
              )}
            </>
          )}
          <div
            style={{
              position: "absolute",
              bottom: 10,
              left: "51%",
              transform: "translateX(-50%)",
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
            onClick={handleScrollToMain}
          >
            <span style={{ color: "#FFFFFF" }}>Scroll Down</span>
            <IconButton onClick={handleScrollToMain}>
              <ExpandMoreIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </ThemeProvider>
      <main ref={mainRef} style={{ marginTop: "100px" }}>
        {showScrollUp && (
          <div
            style={{
              position: "fixed",
              top: "30px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              ReactGA.event({
                category: "Scroll up Clicked",
                action: "Click",
              });
              window.scrollTo(0, 0);
            }}
          >
            <span style={{ marginRight: "4px", color: "#FFFFFF" }}>
              Scroll Up
            </span>
            <IconButton
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <ExpandMoreIcon
                fontSize="large"
                style={{ transform: "rotate(180deg)", color: "white" }}
              />
            </IconButton>
          </div>
        )}

        <TechScouting />
      </main>
    </div>
  );
}

export default HomePage;
