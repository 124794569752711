import React from 'react';

function Loading() {
  const colors = ['#52A9A4', '#70DFD8', '#62B5D1', '#83D3CA', '#ADDAE9'];
  return (
    <div style={{
    }}>
      <div style={{ display: 'flex' }}>
        {colors.map((color, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              width: '2em',
              height: '2em',
              margin: '0.8em',
              borderRadius: '50%',
              background: color,
              animation: `wave 2s ease-out ${index * 0.2}s infinite`
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: color,
                borderRadius: '50%',
                animationName: 'wave',
                animationDuration: '2s',
                animationTimingFunction: 'ease-out',
                animationDelay: `${index * 0.2}s`,
                animationIterationCount: 'infinite'
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Loading;
