import React, { useState, useEffect, useRef, useCallback } from "react";
import "../App.css";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import Button from "@mui/material/Button";
import { ReactComponent as LogoSVG } from "../assets/svg/logo6.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import ReactEcharts from "echarts-for-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Typography,
  ButtonGroup,
  InputBase,
  Tooltip,
  Autocomplete,
  TextField
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import ReactGA from "react-ga4";
import { jwtDecode } from "jwt-decode";
import { debounce } from 'lodash';

echarts.use([LineChart, GridComponent, TooltipComponent, CanvasRenderer]);
const getOption = (data) => {
  const maxDataValue = Math.max(...data);
  const minDataValue = Math.min(...data);

  return {
    grid: {
      top: "15px",
      left: "15px",
      right: "15px",
      bottom: "9px",
    },
    xAxis: {
      type: "category",
      data: data,
      show: false,
    },
    yAxis: {
      min: minDataValue,
      max: maxDataValue,
      type: "value",
      show: false,
    },
    series: [
      {
        data: data,
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#3D766F",
        },
        lineStyle: {
          width: 2,
          color: "#3D766F",
        },
        showSymbol: false,
      },
    ],
  };
};

const StyledTableCellBelow = styled(TableCell)(({ theme, columnType }) => ({
  fontSize: 14,
  height: 48,
  backgroundColor: "#FFFFFF",
  ...{
    id: {
      fontWeight: 700,
      padding: "0px 4px",
      paddingLeft: "15px",
      width: "5%",
    },
    article: {
      padding: "0px 4px",
      width: "25%",
    },
    source: {
      padding: "0px 4px",
      width: "16.66%",
    },
    issueby: {
      padding: "16px 0",
      width: "16.66%",
      textAlign: "left",
    },
    date: {
      padding: "16px 0",
      textAlign: "center",
      width: "16.66%",
    },
  }[columnType || "concept"],
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "none",
    boxShadow: "0px -1px 0px 0px #0000001A inset",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
  },
}));
const StyledTableCellSkeleton = styled(StyledTableCellBelow)(({ theme }) => ({
  padding: 10,
  borderBottom: "none",
  boxShadow: "none",
  backgroundColor: "transparent",
  [`&.${tableCellClasses.head}`]: {
    padding: "0",
    boxShadow: "none",
  },
  [`&.${tableCellClasses.body}`]: {},
}));
const StyledTableRow = styled(TableRow)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? "#F5F5F580" : "transparent",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function StyledTableRowSkeletonRight() {
  return (
    <StyledTableRow>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell keyword-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
    </StyledTableRow>
  );
}

function Navbar({ openModal }) {
  return (
    <div className="navbar2" style={{ borderBottom: "1px solid lightgray" }}>
      <Link to="/">
        <LogoSVG />
      </Link>
      <div className="search-icon-wrapper" onClick={openModal}>
        <SearchIcon className="tech-search-icon" />
      </div>
    </div>
  );
}
const IconTableCell = styled(TableCell)({
  height: "68px",
  marginTop: "8px",
});

const StyledTableCell = styled(TableCell)(({ theme, columnType }) => ({
  fontSize: 14,
  height: 48,
  "&:last-child": {
    paddingRight: 8,
  },
  ...{
    id: {
      fontFamily: "Roboto",
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#00000066",
      fontWeight: "bold",
      width: "4% !important",
    },
    concept: {
      padding: "0px 4px",
      width: "35%",
    },
    keywords: {
      padding: "0px 4px",
      width: "20%",
    },
    "2y": {
      padding: "0px 4px",
      width: "20%",
    },
    "1y": {
      padding: "0x 4px",
      width: "20%",
    },
    icon: {
      padding: "16px 0",
      textAlign: "center",
      width: "5%",
    },
  }[columnType || "concept"],
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "none",
    boxShadow: "0px -1px 0px 0px #0000001A inset",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
  },
}));
const StyledTableCellRight = styled(TableCell)(({ theme, columnType }) => ({
  fontSize: 14,
  height: 48,
  backgroundColor: "#FFFFFF",
  ...{
    keywords: {
      padding: "0px 4px",
      width: "20%",
    },
    articles: {
      padding: "0px 4px",
      width: "20%",
    },
    chart: {
      padding: "0px 4px",
      width: "100px",
    },
    icon: {
      padding: "16px 0",
      textAlign: "center",
      width: "5%",
    },
  }[columnType || "concept"],
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "none",
    boxShadow: "0px -1px 0px 0px #0000001A inset",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
  },
}));

function RightTable({ data, isLoading, industry, period, cluster, keyword }) {
  const [visibleData, setVisibleData] = useState([]);

  useEffect(() => {
    if (data && data.keywords) {
      setVisibleData(data.keywords);
    }
  }, [data]);
  const tableRef = useRef(null);

  const containerRef = useRef(null);
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      elevation={1}
      style={{ maxHeight: "870px", overflow: "auto" }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="customized table">
        <TableHead sx={{ background: "#FFFFF", borderTop: "none" }}>
          <TableRow>
            <TableCell>#</TableCell>
            <StyledTableCellRight columnType="icon"></StyledTableCellRight>
            <StyledTableCellRight>Keyword</StyledTableCellRight>
            <StyledTableCellRight>
              Appear in number of articles
            </StyledTableCellRight>
            <StyledTableCellRight>2y amount trends</StyledTableCellRight>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading
            ? Array(9)
                .fill(0)
                .map((_, idx) => <StyledTableRowSkeletonRight key={idx} />)
            : visibleData.map((item, index) => (
                <StyledTableRow
                  key={index}
                >
                  <TableCell
                    columnType="icon"
                    align="left"
                    style={{
                      backgroundColor: "#FFFFFF",
                      width: "5%",
                      borderBottom: "0",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <IconTableCell
                    style={{
                      padding: "0px",
                      color: "#3D766F",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Link
                      to={`/TechReadiness?keyword=${item.name}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        ReactGA.event({
                          category: "Keyword List Table",
                          action: "Keyword Clicked",
                          label: item.name,
                        });
                      }}
                    >
                      <InsertChartOutlinedIcon />
                    </Link>
                  </IconTableCell>
                  <StyledTableCell
                    columnType="keywords"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    <Link
                      to={`/TechReadiness?keyword=${item.name}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        ReactGA.event({
                          category: "Keyword List Table",
                          action: "Keyword Clicked",
                          label: item.name,
                        });
                      }}
                    >
                      {item.name}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCellRight columnType="keywords">
                    <Link
                      to={`/TechNavigatorArticle?keyword=${item.name}&concept=${keyword}&cluster=${cluster}&services=${industry}&period=${period}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        ReactGA.event({
                          category: "Article Navigation",
                          action: "Article Clicked",
                          label: item.keyword_name,
                          value: item.articles.length,
                        });
                      }}
                    >
                      {item.appear_times}
                    </Link>
                  </StyledTableCellRight>
                  <StyledTableCellRight style={{ width: "5%", height: "70%" }}>
                    <div
                      ref={containerRef}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#efefef",
                        width: "90%",
                        height: "100%",
                      }}
                    >
                      <ReactEcharts
                        style={{ height: "50px", width: "250px" }}
                        option={getOption(item.trend_changes)}
                      />
                    </div>
                  </StyledTableCellRight>
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
function KeywordList() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cluster = searchParams.get("cluster")
  const keyword = searchParams.get("keyword");
  const services = searchParams.get("services");
  const servicesList = services.split(",");
  const numIndustries = servicesList.length;
  const period = searchParams.get("period");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("TechScouting");
  const [conceptInput, setConceptInput] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const latestInputValueRef = useRef("");
  const [searchHistory, setSearchHistory] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
        const decodedToken = jwtDecode(token);
        const isExpired = decodedToken.exp * 1000 < new Date().getTime();
        if (isExpired) {
          navigate('/Login?reset=accountExpired');
        }
    }
  }, [navigate]);
  useEffect(() => {
    const fetchSearchHistory = async () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_V2}/v1/search_history`,
          { headers }
        );
        setSearchHistory(response.data.results);
      } catch (error) {
        console.error("Error fetching search history:", error);
      }
    };

    fetchSearchHistory();
  }, []);
  const fetchAutocompleteOptions = async (prefix) => {
    if (!prefix) return;
    setLoading(true);

    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v1/autocomplete`,
        {
          headers,
          params: { prefix: prefix },
        }
      );

      // Only update options if the input value hasn't changed
      if (prefix === latestInputValueRef.current) {
        setOptions(response.data.results);
      }
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    } finally {
      setLoading(false);
    }
  };
  const debouncedFetchAutocompleteOptions = useCallback(
    debounce((newInputValue) => fetchAutocompleteOptions(newInputValue), 300),
    []
  );
  // const handleChange = (newInputValue) => {
  //   setConceptInput(newInputValue);
  //   debouncedFetchAutocompleteOptions(newInputValue);
  // };
  const handleChange = (newInputValue) => {
    setConceptInput(newInputValue);
    latestInputValueRef.current = newInputValue;
  
    if (!newInputValue) {
      debouncedFetchAutocompleteOptions.cancel();
      setOptions(searchHistory); // Show search history
      setValue(null);
    } else {
      debouncedFetchAutocompleteOptions(newInputValue);
    }
  };
  // const handleOptionChange = (event, newValue) => {
  //   setValue(newValue);

  //   // If an option is selected, set the searchTerm based on Abbv or Phrase
  //   if (typeof newValue === "object" && newValue !== null) {
  //     setConceptInput(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //   } else if (typeof newValue === "string") {
  //     // If a string is entered (free solo), use it directly
  //     setConceptInput(newValue);
  //   }
  // };
  const handleOptionChange = (event, newValue) => {
    setValue(newValue);
    
    // If an option is selected, set the searchTerm based on Abbv or Phrase
    let termToSearch = '';
    if (typeof newValue === 'object' && newValue !== null) {
      termToSearch = newValue.Abbv ? newValue.Abbv : newValue.Phrase;
      setConceptInput(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
    } else if (typeof newValue === 'string') {
      // If a string is entered (free solo), use it directly
      termToSearch = newValue;
      setConceptInput(newValue);
    }
  
    if (termToSearch) {
      ReactGA.event("search_performed", {
        category: "Autocomplete Option Clicked",
        label: "Autocomplete Clicked",
        search_term: termToSearch,
      });
      // Navigate to the appropriate page based on the selected button
      if (selectedButton === "TechScouting") {
        navigate(`/TechScoutingResult?keywords=${encodeURIComponent(termToSearch)}&activity=search`);
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(termToSearch)}&activity=search`);
      }
    } else {
      setConceptInput('');
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const category = selectedButton; // "TechScouting" or "TechReadiness"
      const event_name = "search_performed";

      if (selectedButton === "TechScouting") {
        navigate(
          `/TechScoutingResult?keywords=${encodeURIComponent(conceptInput)}&activity=search`
        );
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(conceptInput)}&activity=search`);
      }
    }
  };
  const handleInputFocus = () => {
    if (!conceptInput) {
      setOptions(searchHistory); // Show search history on focus
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const fetchKeywordsData = async (industry, period, cluster) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL_V2
        }/v1/technology/keywords?cluster=${encodeURIComponent(cluster)}&industries=${encodeURIComponent(industry)}&period=${encodeURIComponent(period)}`,
        { headers: headers }
      );
      setIsLoading(false);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.removeItem("access_token");
        alert("Please Login Again.");
        window.location.reload();
      } else {
        console.error("Error fetching keywords data:", error);
      }
      setIsLoading(false);
      return null;
    }
  };
  const [clusterData, setClusterData] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await fetchKeywordsData(services, period, cluster);
      setClusterData(data)
    })();
  }, [keyword, services, period,cluster]);
  const handleSearch = () => {
    // setSearchTerm(conceptInput);
    if (selectedButton === "TechScouting") {
      navigate(`/TechScoutingResult?keywords=${conceptInput}`);
    } else if (selectedButton === "TechReadiness") {
      navigate(`/TechReadiness?keyword=${conceptInput}`);
    }
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    setOptions(searchHistory)
    ReactGA.event({
      category: "User Interaction",
      action: "Opened Modal",
      label: "Search Modal",
    });
  };

  return (
    <div className="white-background">
      <Navbar openModal={handleOpenModal} />
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setOptions([]);
        }}
        aria-labelledby="search-modal"
        aria-describedby="search-functionality"
        align="right"
        sx={{
          position: "absolute",
          top: "70px",
          right: "30px",
        }}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "420px",
            height: "220px",
            borderRadius: "8px",
            padding: "10px",
            outline: "none",
            textAlign: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            id="search-modal"
            align="left"
            style={{
              paddingLeft: "13px",
              paddingBottom: "20px",
              fontFamily: "Glory",
              fontWeight: "700",
            }}
          >
            Search
          </Typography>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="text primary button group"
          >
            <Button
              onClick={() => setSelectedButton("TechScouting")}
              style={
                selectedButton === "TechScouting"
                  ? {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Scouting
            </Button>
            <Button
              onClick={() => setSelectedButton("TechReadiness")}
              style={
                selectedButton === "TechReadiness"
                  ? {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Readiness Assessment
            </Button>
          </ButtonGroup>

          <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option.Abbv
                ? `${option.Abbv} - ${option.Phrase}`
                : option.Phrase
            } 
            filterOptions={(options) => options}
            onInputChange={(event, newInputValue) =>
              handleChange(newInputValue)
            }
            onFocus={handleInputFocus}
            onChange={handleOptionChange}
            onKeyDown={handleKeyPress}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  selectedButton === "TechReadiness"
                    ? "Enter a specific technology to assess technology maturity"
                    : "Enter one or more fields of interest, separated by ,"
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  style: {
                    width: "400px",
                    borderRadius: "0 0 4px 4px",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    fontFamily: "Glory",
                    marginLeft: "10px",
                  },
                }}
              />
            )}
          />

          <Button
            variant="contained"
            style={{
              width: "400px",
              height: "48px",
              marginTop: "30px",
              borderRadius: "4px",
              backgroundColor: "#435452",
              color: "white",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Modal>
      <div className="tech-group-3">
        <span style={{ display: "block" }}>
          <div
            style={{
              width: "4px",
              height: "18px",
              marginRight: "10px",
              background: "#3D766F",
              display: "inline-block",
            }}
          ></div>
        </span>
        <Tooltip title={services}>
          <span
            style={{
              color: "#435452",
              fontFamily: "Glory, sans-serif",
              fontSize: "18px",
              fontWeight: 500,
              cursor: "pointer",
            }}
          >
            {services.includes(",") ? (
              <>
                {servicesList[0].replace(/and /g, "& ")}
                <span style={{ color: "#00000066", paddingLeft: "10px" }}>
                  & other {numIndustries - 1} industries
                </span>
              </>
            ) : (
              services
            )}
          </span>
        </Tooltip>
      </div>
      <div className="tech-group-4">
        <span style={{ color: "#435452" }}>
          {keyword}
          {clusterData ? (
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Glory",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0.01em",
                textAlign: "left",
                color: "#00000066",
              }}
            >
              ({clusterData.keywords.length})
            </span>
          ) : null}
        </span>
      </div>

      <div className="tableContainer">
        <div style={{ display: "flex", maxHeight: "480px" }}>
          <Paper style={{ flex: 1, marginBottom: "10px" }}>
            <RightTable
              data={clusterData}
              isLoading={isLoading}
              industry={encodeURIComponent(services)}
              period={period}
              cluster={cluster}
              keyword={keyword}
            />
          </Paper>
        </div>
      </div>
      <div style={{ height: "100px", marginTop: "300px" }}></div>
    </div>
  );
}

export default KeywordList;
