import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Link,
  InputAdornment,
  ThemeProvider,
  createTheme,
  Grid,
} from "@mui/material";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import {
  Key,
  Eye,
  ArrowRight,
  CheckCircle,
  EyeClosed,
  SpinnerGap,
} from "phosphor-react";
import { ReactComponent as LogoSVG } from "../assets/svg/logo6.svg";
import { ReactComponent as BackgroundSVG } from "../assets/svg/vector.svg";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0EA996",
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: "aileron, Arial, sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&$focused $notchedOutline": {
            borderColor: "#0EA996",
          },
          "&.Mui-error $notchedOutline": {
            borderColor: "#f44336",
          },
        },
        notchedOutline: {
          borderColor: "#ccc",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&$focused": {
            color: "#0EA996",
          },
          "&.Mui-error": {
            color: "#f44336",
          },
        },
      },
    },
  },
});
const ResetPassword = React.memo(() =>  {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmedPasswordVisibility = () =>
    setShowConfirmedPassword(!showConfirmedPassword);

  const handleReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const formData = new FormData();
    formData.append("password", password);

    if (!loginError && token) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL_V2}/auth/reset_password`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        window.location.href = "/Login?reset=success";
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const responseData = error.response.data;
          if (
            responseData &&
            responseData.error ===
              "password is the same as the current password"
          ) {
            setLoginError(true);
            setErrorMessage(
              "The new password must be different from the current password."
            );
          } else {
            setLoginError(true);
            setErrorMessage(
              responseData.message ||
                "Failed to reset password. Please try again."
            );
          }
        } else {
          setLoginError(true);
          setErrorMessage(
            error.response?.data?.message ||
              "An unexpected error occurred. Please try again."
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (setter) => (e) => {
    const value = e.target.value;
    setter(value);

    setMinChars(value.length >= 8);
    setUpperCase(/[A-Z]/.test(value));
    setLowerCase(/[a-z]/.test(value));
    setNumber(/\d/.test(value));
    setSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    if (value !== password) {
      setLoginError(true);
      setErrorMessage("The password does not match, please re-enter it.");
    } else {
      setLoginError(false);
      setErrorMessage("");
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const isExpired = decodedToken.exp * 1000 < new Date().getTime();
      if (isExpired) {
        window.location.href = "/Login?reset=expired";
      } else {
        setEmail(decodedToken.email);
      }
    }
  }, [location]);

  const [minChars, setMinChars] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const isFormFilled =
    minChars &&
    upperCase &&
    lowerCase &&
    number &&
    specialChar &&
    confirmPassword === password;
  const isPasswordStart = password.length > 0;
  const isConfirmPasswordStart = confirmPassword.length > 0;
  const opacityPassword = isPasswordStart ? 1 : 0.5;
  const opacityConfirmPassword = isConfirmPasswordStart ? 1 : 0.5;
  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="ms"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#132B24",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <LazyLoadComponent>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 0,
            }}
          >
            <BackgroundSVG style={{ width: "100%", height: "auto" }} />
          </Box>
        </LazyLoadComponent>
        <Card
          sx={{
            width: "430px",
            height: "518px",
            padding: "32px 24px",
            gap: "24px",
            borderRadius: "12px",
            opacity: "1",
            position: "absolute",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            animation: "fadeInUp 1s ease-in-out",
          }}
        >
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <LogoSVG style={{ width: 120, height: 60 }} />
            <Typography
              component="h1"
              variant="h5"
              sx={{ mb: 2, fontWeight: 600, fontSize: "24px" }}
            >
              Reset password to Immortal
            </Typography>
            <Box component="form" sx={{ width: "100%" }} onSubmit={handleReset}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="New Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={handleChange(setPassword)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Key
                        size={24}
                        style={{ opacity: opacityPassword, color: "#132B24" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={togglePasswordVisibility}
                        sx={{
                          padding: 0,
                          minWidth: "auto",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            outline: "none",
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                        }}
                      >
                        {showPassword ? (
                          <EyeClosed
                            size={24}
                            style={{
                              opacity: 0.5,
                              color: "#132B24",
                            }}
                            weight="fill"
                          />
                        ) : (
                          <Eye
                            size={24}
                            style={{
                              opacity: opacityPassword,
                              color: "#132B24",
                            }}
                            weight="fill"
                          />
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: loginError ? "#F64747" : "#C4C4C4",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: loginError ? "#F64747" : "#0EA996",
                    },
                  },
                  marginBottom: "1px",
                }}
                error={loginError}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Re-enter New Password"
                type={showConfirmedPassword ? "text" : "password"}
                id="password"
                onChange={handleConfirmPasswordChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Key
                        size={24}
                        style={{
                          opacity: opacityConfirmPassword,
                          color: "#132B24",
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={toggleConfirmedPasswordVisibility}
                        style={{ padding: 0, minWidth: "auto" }}
                      >
                        {showConfirmedPassword ? (
                          <EyeClosed
                            size={24}
                            style={{
                              opacity: 0.5,
                              color: "#132B24",
                            }}
                            weight="fill"
                          />
                        ) : (
                          <Eye
                            size={24}
                            style={{
                              opacity: opacityConfirmPassword,
                              color: "#132B24",
                            }}
                            weight="fill"
                          />
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: loginError ? "#F64747" : "#C4C4C4",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: loginError ? "#F64747" : "#0EA996",
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Aileron, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#F64747",
                  },
                }}
                error={loginError}
                helperText={loginError ? errorMessage : ""}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <CheckCircle
                        color={minChars ? "#0EA996" : "#C1C7CD"}
                        weight="fill"
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          color: minChars ? "#0EA996" : "#A2A9B0",
                          margin: 0.2,
                        }}
                      >
                        At Least 8 Characters
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <CheckCircle
                        color={lowerCase ? "#0EA996" : "#C1C7CD"}
                        weight="fill"
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          color: lowerCase ? "#0EA996" : "#A2A9B0",
                          margin: 0.2,
                        }}
                        weight="fill"
                      >
                        Lower Case Letters (a-z)
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <CheckCircle
                        color={specialChar ? "#0EA996" : "#C1C7CD"}
                        weight="fill"
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          color: specialChar ? "#0EA996" : "#A2A9B0",
                          margin: 0.2,
                        }}
                      >
                        Special Characters (*#@(!)
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <CheckCircle
                        color={upperCase ? "#0EA996" : "#C1C7CD"}
                        weight="fill"
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          color: upperCase ? "#0EA996" : "#C1C7CD",
                          margin: 0.2,
                        }}
                      >
                        Upper Case Letters (A-Z)
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <CheckCircle
                        color={number ? "#0EA996" : "#C1C7CD"}
                        weight="fill"
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          color: number ? "#0EA996" : "#C1C7CD",
                          margin: 0.2,
                        }}
                        weight="fill"
                      >
                        Number (0-9)
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 3.5,
                  height: "48px",
                  backgroundColor: isFormFilled ? "#0EA996" : "#F2F8F7",
                  color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                  fontWeight: 600,
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: isFormFilled ? "#0C9887" : "#F2F8F7",
                    color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                    boxShadow: "none",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: isFormFilled ? "#0EA996" : "#F2F8F7",
                    color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                  },
                }}
                endIcon={
                  loading ? null : (
                    <ArrowRight
                      size={24}
                      color={isFormFilled ? "#FFFFFF" : "#73BDB0"}
                    />
                  )
                }
                disabled={loading}
              >
                {loading ? (
                  <SpinnerGap
                    size={24}
                    color="#FFFFFF"
                    style={{ animation: "spin 1s linear infinite" }}
                  />
                ) : (
                  "Reset Password"
                )}
              </Button>
              <style>
                {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          @keyframes fadeInUp {
            0% { opacity: 0; transform: translateY(20px); }
            100% { opacity: 1; transform: translateY(0); }
          }
        `}
              </style>
            </Box>
            <Typography
              variant="body2"
              color="#343A3F"
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.01em",
              }}
            >
              Already have an account?
              <Link
                href="/Login"
                sx={{
                  color: "#0EA996",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              >
                {" "}
                Sign in{" "}
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
})

export default ResetPassword;
