import React, { useEffect, useRef } from "react";
import { SemiCircle } from "progressbar.js";

export default function SemiCirclePB({ progress }) {
  const pbarRef = useRef(null);

  // Function to calculate color based on score
  const getColor = (score) => {
    // Returns an array [lighterColor, darkerColor]
    if (score <= 0.2) {
      return [`#FFD1CB`, `#E78686`]; // Light red to red
    } else if (score <= 0.4) {
      return [`#FCF299`, `#F2B379`]; // Light orange to orange
    } else if (score <= 0.6) {
      return [`#F9F489`, `#FFD600`]; // Light yellow to yellow
    } else if (score <= 0.8) {
      return [`#9FFEC5`, `#65CC9B`]; // Light green to green
    } else if (score <= 1.0) {
      return [`#9FECFE`,`#6BA8DF`]; // Light blue to blue
    }
  };

  useEffect(() => {
    if (pbarRef.current.innerHTML.trim() === "") {
      // Initialize the progress bar
      const bar = new SemiCircle(pbarRef.current, {
        strokeWidth: 8,
        easing: "easeInOut",
        trailColor: "#F0F0F0",
        trailWidth: 8,
        svgStyle: {
          strokeLinecap: "round",
        },
        text: {
          value: "",
          alignToBottom: true,
        },
        step: (state, bar) => {
          var value = Math.round(bar.value() * 100);
          if (value === 0) {
            bar.setText("0");
          } else {
            bar.setText(value);
          }
          const color = getColor(bar.value());
          bar.text.style.color = color[1]; // Use the darker color for the text
        },
      });

      // Styling for the text
      bar.text.style.fontFamily = '"Glory", sans-serif';
      bar.text.style.fontSize = "3.75rem";
      bar.text.style.fontWeight = "600";

      // Start the animation
      bar.animate(progress);

      // Create the gradient after the animation starts to get the current score
      const color = getColor(progress);
      const svgElement = pbarRef.current.querySelector("svg");
      const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
      const gradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
      gradient.setAttribute("id", "gradient");
      gradient.innerHTML = `
        <stop offset="0%" style="stop-color:${color[0]};" />
        <stop offset="100%" style="stop-color:${color[1]};" />
      `;
      defs.appendChild(gradient);
      svgElement.insertBefore(defs, svgElement.firstChild);

      bar.path.setAttribute("stroke", "url(#gradient)");

    }
  }, [progress]);

  return <div ref={pbarRef}></div>;
}
