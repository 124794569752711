import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TechScouting from "./components/TechScouting";
import TechScoutingResult from "./components/TechScoutingResult";
import TechScoutingArticle from "./components/TechScoutingArticle";
import TechReadiness from "./components/TechReadiness";
import TechReadinessArticle from "./components/TechReadinessArticle";
import HomePage from "./components/HomePage";
import KeywordList from "./components/KeywordList";
import TechScoutingTopArticle from "./components/TechScoutingTopArticle";
import TechNavigatorArticle from "./components/TechNavigatorArticle";
import PrivateWrapper from "./components/PrivateWrapper";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./components/Login";
import ReactGA from "react-ga4";
import SendResetPassword from "./components/SendResetPassword";
import ResetPassword from "./components/ResetPassword";
function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const initializeGoogleAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.send("pageview");
  };
  useEffect(() => {
    initializeGoogleAnalytics();

    const checkForAccessToken = () => {
      const accessToken = localStorage.getItem("access_token");
      if (accessToken && !isUserLoggedIn) {
        const userID = accessToken.split("_")[1];
        ReactGA.set({ userId: userID });
        setIsUserLoggedIn(true);
      }
    };
    checkForAccessToken();
    const checkLoginInterval = setInterval(checkForAccessToken, 1000);

    return () => {
      clearInterval(checkLoginInterval);
    };
  }, [isUserLoggedIn]);
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {/* Define routes */}
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/SendResetPassword" element={<SendResetPassword />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route
            path="/techScouting"
            element={
              <PrivateWrapper>
                <TechScouting />
              </PrivateWrapper>
            }
          />
          <Route
            path="/TechScoutingResult"
            element={
              <PrivateWrapper>
                <TechScoutingResult />
              </PrivateWrapper>
            }
          />
          <Route
            path="/TechScoutingArticle"
            element={
              <PrivateWrapper>
                <TechScoutingArticle />
              </PrivateWrapper>
            }
          />
          <Route
            path="/TechScoutingTopArticle"
            element={
              <PrivateWrapper>
                <TechScoutingTopArticle />
              </PrivateWrapper>
            }
          />
          <Route
            path="/TechReadinessArticle"
            element={
              <PrivateWrapper>
                <TechReadinessArticle />
              </PrivateWrapper>
            }
          />
          <Route
            path="/TechReadiness"
            element={
              <PrivateWrapper>
                <TechReadiness />
              </PrivateWrapper>
            }
          />
          <Route
            path="/"
            element={
              <PrivateWrapper>
                <HomePage />
              </PrivateWrapper>
            }
          />
          <Route
            path="/KeywordList"
            element={
              <PrivateWrapper>
                {" "}
                <KeywordList />
              </PrivateWrapper>
            }
          />
          <Route
            path="/TechNavigatorArticle"
            element={
              <PrivateWrapper>
                <TechNavigatorArticle />
              </PrivateWrapper>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
