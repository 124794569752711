import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Collapse,
  List,
  Chip,
} from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function isContinentFullySelected(continent, selectedRegions) {
  return continent.countries.every((country) =>
    selectedRegions.includes(country)
  );
}

export default function SettingsDialog({
  isOpen,
  onClose,
  period,
  setPeriod,
  handlePeriodChange,
  customDays,
  setCustomDays,
  sortCriteria,
  setSortCriteria,
  handleSortCriteriaChange,
  regionCountryMap,
  cancelDialog
}) {
  // const [sortCriteria, setSortCriteria] = useState("percentChanged");
  const continents = Object.keys(regionCountryMap).map((key) => ({
    name: key,
    countries: regionCountryMap[key],
  }));
  const allCountries = [].concat(...Object.values(regionCountryMap));
  const allRegions = [...Object.keys(regionCountryMap), ...allCountries];
  const [regions, setRegions] = useState(allRegions);
  useEffect(() => {
    if (regionCountryMap && Object.keys(regionCountryMap).length > 0) {
      const allCountries = [].concat(...Object.values(regionCountryMap));
      const allRegions = [...Object.keys(regionCountryMap), ...allCountries];
      setRegions(allRegions);
    }
  }, [regionCountryMap]);
  

  const periodOptions = [
    { value: "90", label: "90 days" },
    { value: "180", label: "180 days" },
    { value: "365", label: "1 year" },
    { value: "730", label: "2 years" },
    // Remove 'Other' here as we will handle it separately below
  ];
  const isContinentFullySelected = (continent, selectedRegions) => {
    return continent.countries.every((country) =>
      selectedRegions.includes(country)
    );
  };

  const isContinentPartiallySelected = (continent, selectedRegions) => {
    return (
      continent.countries.some((country) =>
        selectedRegions.includes(country)
      ) && !isContinentFullySelected(continent, selectedRegions)
    );
  };
  const handleRegionChange = (event) => {
    const { value } = event.target;
    setRegions((prev) => {
      const currentIndex = prev.indexOf(value);
      const newRegions = [...prev];

      if (currentIndex === -1) {
        newRegions.push(value);
      } else {
        newRegions.splice(currentIndex, 1);
      }

      return newRegions;
    });
  };
  const handleCountryChange = (country) => {
    setRegions((prev) => {
      if (prev.includes(country)) {
        return prev.filter((r) => r !== country);
      } else {
        return [...prev, country];
      }
    });
  };

  const handleContinentChange = (continent) => {
    let newRegions;
    if (
      isContinentFullySelected(continent, regions) ||
      isContinentPartiallySelected(continent, regions)
    ) {
      // Remove the continent and its countries from the regions.
      newRegions = regions.filter(
        (region) =>
          region !== continent.name && !continent.countries.includes(region)
      );
    } else {
      // Add the continent and all its countries to the regions.
      newRegions = [
        ...regions,
        continent.name,
        ...continent.countries.filter((country) => !regions.includes(country)),
      ];
    }

    // Check if all checkboxes are unchecked.
    if (
      newRegions.length === continents.length &&
      !continents.some((cont) => isContinentFullySelected(cont, newRegions))
    ) {
      newRegions = [];
    }

    setRegions(newRegions);
  };

  const [expandedContinent, setExpandedContinent] = useState(null);

  const handleToggleContinent = (continentName) => {
    setExpandedContinent((prev) =>
      prev !== continentName ? continentName : null
    );
  };

  const MAX_DISPLAYED_CHIPS = 5; // Adjust this number based on your UI requirement

  const renderSelectedRegions = (selectedRegions) => {
    const displayedRegions = selectedRegions.slice(0, MAX_DISPLAYED_CHIPS);
    const remainingCount = selectedRegions.length - MAX_DISPLAYED_CHIPS;

    return (
      <>
        {displayedRegions.map((region, index) => (
          <Chip
            key={index}
            label={region}
            onDelete={() => handleDeleteRegion(region)}
            style={{
              marginRight: "5px",
              color: "#83D3CA",
              "& .MuiChipDeleteIcon": {
                color: "black",
                backgroundColor: "#83D3CA",
              },
            }}
          />
        ))}
        {remainingCount > 0 && (
          <Chip
            label={`... +${remainingCount}`}
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={() => {
              // Optional: You can add a logic here, e.g., show a dialog listing all regions
            }}
          />
        )}
      </>
    );
  };

  const handleDeleteRegion = (regionToRemove) => {
    const newRegions = regions.filter((region) => region !== regionToRemove);
    setRegions(newRegions);
  };

  // const handleSortCriteriaChange = (event) => {
  //   setSortCriteria(event.target.value);
  // };

  return (
    <Dialog
      open={isOpen}
      onClose={cancelDialog}
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick={true}
      PaperProps={{
        style: {
          width: "700px",
        },
      }}
    >
      <DialogTitle sx={{ fontFamily: "Glory, sans-serif", fontWeight: "bold" }}>
        Settings
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Glory, sans-serif",
                fontWeight: "bold",
                fontSize: "18px",
                display: "inline", // This is to make sure the next Typography component appears on the same line
              }}
            >
              Period
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "gray",
                fontSize: "14px",
                marginLeft: "5px", // Some spacing between the two texts
                display: "inline",
              }}
            >
              ago from now
            </Typography>
          </Grid>

          <Grid item container>
            <RadioGroup row value={period} onChange={handlePeriodChange}>
              {periodOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#83D3CA",
                        },
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
              {/* Include 'Other' radio and TextField here */}
              <FormControlLabel
                value="other"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#83D3CA",
                      },
                    }}
                  />
                }
                label="others"
              />
              {period === "other" && (
                <>
                  <TextField
                    variant="standard"
                    value={customDays}
                    onChange={(e) => setCustomDays(e.target.value)}
                    size="small"
                    style={{ width: "50px", marginRight: "5px" }}
                  />
                  <Typography sx={{ marginTop: "10px", marginLeft: "10px" }}>
                    days
                  </Typography>
                </>
              )}
            </RadioGroup>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Glory, sans-serif",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Regions of source articles
            </Typography>
          </Grid>
          <Grid item>
            <FormControl fullWidth variant="standard">
              <InputLabel
                htmlFor="regions-select"
                shrink
                sx={{
                  color: "#83D3CA",
                  "&.Mui-focused": {
                    color: "#83D3CA",
                  },
                }}
              >
                Regions
              </InputLabel>
              <Select
                multiple
                value={regions}
                onChange={handleRegionChange}
                id="regions-select"
                label="Regions"
                renderValue={renderSelectedRegions}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "#83D3CA",
                  },
                  "&.Mui-focused": {
                    // This targets the focused state
                    // outline: '2px solid #83D3CA',
                    borderColor: "#83D3CA",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#83D3CA",
                  },
                }}
              >
                {continents.map((continent) => (
                  <div key={continent.name}>
                    <MenuItem
                      value={continent.name}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleToggleContinent(continent.name);
                      }}
                    >
                      <Checkbox
                        checked={
                          regions.includes(continent.name) ||
                          isContinentFullySelected(continent, regions)
                        }
                        indeterminate={isContinentPartiallySelected(
                          continent,
                          regions
                        )}
                        onChange={(event) => {
                          event.stopPropagation();
                          handleContinentChange(continent);
                        }}
                        sx={{ "&.Mui-checked": { color: "#83D3CA" } }}
                      />
                      <ListItemText primary={continent.name} />
                      {expandedContinent === continent.name ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </MenuItem>

                    <Collapse
                      in={expandedContinent === continent.name}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {continent.countries.map((country) => (
                          <MenuItem
                            key={country}
                            value={country}
                            sx={{ paddingLeft: 4 }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              checked={regions.includes(country)}
                              onChange={() => handleCountryChange(country)}
                              sx={{ "&.Mui-checked": { color: "#83D3CA" } }}
                            />
                            <ListItemText primary={country} />
                          </MenuItem>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Glory, sans-serif",
                fontWeight: "bold",
                fontSize: "18px",
                display: "inline", // This is to make sure the next Typography component appears on the same line
              }}
            >
              Sorting Criteria
            </Typography>
          </Grid>

          <Grid item container>
            <RadioGroup
              row
              value={sortCriteria}
              onChange={handleSortCriteriaChange}
            >
              <FormControlLabel
                value="percentChanged"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#83D3CA",
                      },
                    }}
                  />
                }
                label="Percent Changed"
              />
              <FormControlLabel
                value="relevantKeyword"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#83D3CA",
                      },
                    }}
                  />
                }
                label="Number of Relevant Keywords"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      {/* The rest of your Dialog content goes here... */}
      <DialogActions>
        <Button onClick={cancelDialog} sx={{ color: "#83D3CA", fontWeight: "bold" }}>
          Cancel
        </Button>
        <Button onClick={onClose} sx={{ color: "#83D3CA", fontWeight: "bold" }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
