import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Link,
  InputAdornment,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { EnvelopeSimple, ArrowRight, SpinnerGap } from "phosphor-react";
import { ReactComponent as LogoSVG } from "../assets/svg/logo6.svg";
import { ReactComponent as BackgroundSVG } from "../assets/svg/vector.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0EA996",
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: "aileron, Arial, sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&$focused $notchedOutline": {
            borderColor: "#0EA996",
          },
          "&.Mui-error $notchedOutline": {
            borderColor: "#f44336",
          },
        },
        notchedOutline: {
          borderColor: "#ccc",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&$focused": {
            color: "#0EA996",
          },
          "&.Mui-error": {
            color: "#f44336",
          },
        },
      },
    },
  },
});

const SendResetPassword = React.memo(() =>  {
  const [email, setEmail] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const validateEmail = (email) => {
    // Regular expression to test the validity of the email
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateEmail(email)) {
      setLoginError(true);
      setErrorMessage("Enter a valid email address");
      setLoading(false);
      return;
    }
    const apiUrl = `${process.env.REACT_APP_API_URL_V2}/auth/send_reset_password_email`;
    const formData = new FormData();
    formData.append("email", email);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      window.location.href = "/Login?reset=send";
    } catch (error) {
      console.error("Error sending reset password email:", error);
      setLoginError(true);
      setErrorMessage(
        error.response?.data?.error || "An unexpected error occurred"
      );
      setOpenSnackbar(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
    if (loginError) {
      setLoginError(false);
      setErrorMessage("");
    }
  };

  const isFormFilled = email.length > 0;
  const isEmailStart = email.length > 0;
  const opacityEmail = isEmailStart ? 1 : 0.5;

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="ms"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#132B24",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <LazyLoadComponent>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 0,
            }}
          >
            <BackgroundSVG style={{ width: "100%", height: "auto" }} />
          </Box>
        </LazyLoadComponent>
        <Card
          sx={{
            width: 430,
            height: 362,
            borderRadius: "12px",
            padding: "32px 24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            position: "relative",
            zIndex: 1,
            animation: "fadeInUp 1s ease-in-out",
          }}
        >
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <LogoSVG style={{ width: 120, height: 60 }} />
            <Typography
              sx={{
                mb: 2,
                fontWeight: 600,
                fontSize: "24px",
                color: "#132B24",
                fontFamily: " Aileron",
              }}
            >
              Reset Password to Immortal
            </Typography>
            <Box
              component="form"
              sx={{ width: "100%" }}
              onSubmit={handleResetPassword}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Work Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange(setEmail)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EnvelopeSimple
                        size={24}
                        style={{ opacity: opacityEmail, color: "#132B24" }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: loginError ? "#F64747" : "#C4C4C4",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: loginError ? "#F64747" : "#0EA996",
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Aileron, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#F64747",
                  },
                }}
                error={loginError}
                helperText={loginError ? errorMessage : ""}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 2,
                  height: "48px",
                  backgroundColor: isFormFilled ? "#0EA996" : "#F2F8F7",
                  color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                  boxShadow: "none",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: isFormFilled ? "#0C9887" : "#F2F8F7",
                    color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                    boxShadow: "none",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: isFormFilled ? "#0EA996" : "#F2F8F7",
                    color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                  },
                }}
                endIcon={
                  loading ? null : (
                    <ArrowRight
                      size={24}
                      color={isFormFilled ? "#FFFFFF" : "#73BDB0"}
                    />
                  )
                }
                disabled={loading}
              >
                {loading ? (
                  <SpinnerGap
                    size={24}
                    color="#FFFFFF"
                    style={{ animation: "spin 1s linear infinite" }}
                  />
                ) : (
                  "Continue"
                )}
              </Button>
              <style>
                {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          @keyframes fadeInUp {
            0% { opacity: 0; transform: translateY(20px); }
            100% { opacity: 1; transform: translateY(0); }
          }
        `}
              </style>
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mt: 2,
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.01em",
              }}
            >
              Already have an account?
              <Link
                href="/Login"
                sx={{
                  color: "#0EA996",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              >
                {" "}
                Sign in{" "}
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
})

export default SendResetPassword;
