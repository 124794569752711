import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const DataTable = ({ data, selectedServices, period, sortCriteria }) => {
  const trackGoogleAnalyticsEvent = (category, action, label, value = "") => {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };
  const sortedData = React.useMemo(() => {
    if (sortCriteria === 'relevantKeyword') {
      return [...data].sort((a, b) => b.n_keywords - a.n_keywords);
    } else if (sortCriteria === 'percentChanged') {
      return [...data].sort((a, b) => b.trend_change - a.trend_change);
    }
    return data;
  }, [data, sortCriteria]);
  return (
    <div
      style={{
        width: "80%",
        margin: "0 auto",
        borderRadius: "8px",
      }}
      className="custom-scrollbar"
    >
      <TableContainer
        component={Paper}
        style={{
          backgroundColor: "transparent",
          marginTop: "0px",
          marginBottom: "100px",
          maxHeight: "450px",
          overflow: "auto",
        }}
        sx={{
          maxHeight: "420px",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#83D3CA80",
            borderRadius: "4px",
          },
          "& scrollbarWidth": "thin",
          "& scrollbarColor": "#83D3CA80 transparent",
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            "& .MuiTable-stickyHeader th": {
              backgroundColor: "transparent",
            },
          }}
          style={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  borderBottom: "100px",
                  width: "10px",
                }}
              />
              <TableCell
                style={{
                  color: "#FFFFFF",
                  //   border: "0",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "29px",
                  letterSpacing: "0em",
                  borderBottom: "1",
                }}
              >
                Technology Concepts
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "29px",
                  letterSpacing: "0em",
                  borderBottom: "1",
                }}
              >
                {sortCriteria === "relevantKeyword"
                  ? "Number of Relevant Keywords"
                  : "Percent Changed"}
              </TableCell>
              <TableCell style={{ borderBottom: "0" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell style={{ borderBottom: "0" }}></TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Inter",
                    fontSize: "1.1rem",
                    fontWeight: 500,
                    lineHeight: "29px",
                    letterSpacing: "0em",
                    textAlign: "left",
                  }}
                >
                  <span style={{ marginRight: "50px" }}>{index + 1}</span>
                  <Link
                    to={`/KeywordList?keyword=${
                      row.name
                    }&cluster=${row.label}&services=${selectedServices
                      .map((s) => s.replace(/&/g, "and"))
                      .join(",")}&period=${period}`}
                    style={{ textDecoration: "none", color: "#FFFFFF" }}
                    onClick={() =>
                      trackGoogleAnalyticsEvent(
                        "ListTable",
                        "keyword_click",
                        `Keyword: ${
                          row.name
                        }, Services: ${selectedServices.join(",")}`
                      )
                    }
                    target="_blank"
                  >
                    {row.name}
                  </Link>
                </TableCell>

                <TableCell align="right" style={{ color: "#FFFFFF", fontFamily: "Roboto", fontSize: "16px", fontWeight: 400, lineHeight: "18.75px" }}>
                  {sortCriteria === "relevantKeyword" ? (
                    row.n_keywords
                  ) : (
                    <>
                      {row.trend_change < 0 ? (
                        <ArrowDownwardIcon
                          sx={{ color: "#DD9696", verticalAlign: "middle" }}
                        />
                      ) : (
                        <ArrowUpwardIcon
                          sx={{ color: "#42aa3f", verticalAlign: "middle" }}
                        />
                      )}
                      {Math.abs((row.trend_change * 100).toFixed(0))}%
                    </>
                  )}
                </TableCell>
                <TableCell
                  style={{ borderBottom: "0", width: "10px" }}
                ></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataTable;
