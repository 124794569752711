import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Typography,
  Chip,
  Box,
  Skeleton,
  Stack,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SemiCirclePB from "./SemiCirclePB";
import TriCardPBPopper from "./TriCardPBPopper";
import axios from "axios";

export default function TriCard({ phrase }) {
  // state definitions
  const [score, setScore] = useState(null);
  const [pendingRequest, setPendingRequest] = useState(null);

  // helper functions
  const getDesc = () => {
    if (score !== null) {
      if (score <= 0.2) {
        return `This range signifies that the technology is primarily in the academic research phase, with limited publications and conceptual validation. It's largely unexplored in patents and company reports.`;
      } else if (score <= 0.4) {
        return `The technology has some academic backing and may have initial prototypes. It's beginning to appear in patent databases but is not yet widely adopted in industry reports or forums.`;
      } else if (score <= 0.6) {
        return `The technology has moved beyond the lab and is in the pilot or beta testing phase. It has some market validation, often cited in academic journals, patents, and specialized forums but may lack broad industry reports.`;
      } else if (score <= 0.8) {
        return `The technology is well-documented in academic journals, patents, and company reports. It has been adopted by some industry players and discussed in technology forums, indicating a level of market acceptance.`;
      } else if (score <= 1.0) {
        return `The technology is a standard topic in academic journals, heavily patented, and frequently featured in industry reports and technology forums. It's widely adopted and holds a leading position in the market.`;
      }
    }
    return "";
  };
  const getTagColor = () => {
    if (score !== null) {
      if (score <= 0.2) {
        return `#E78686`;
      } else if (score <= 0.4) {
        return `#F2B379`;
      } else if (score <= 0.6) {
        return `#FBDA2C`;
      } else if (score <= 0.8) {
        return `#65CC9B`;
      } else if (score <= 1.0) {
        return `#69A6DE`;
      }
    }
  };
  const getTagBGColor = () => {
    if (score !== null) {
      if (score <= 0.2) {
        return `#FFDFDF`;
      } else if (score <= 0.4) {
        return `#FFEEDF`;
      } else if (score <= 0.6) {
        return `#FFFAE2`;
      } else if (score <= 0.8) {
        return `#E4FFEA`;
      } else if (score <= 1.0) {
        return `#E4FAFF`;
      }
    }
  };
  const getTag = () => {
    if (score !== null) {
      if (score <= 0.2) {
        return `Research Stage`;
      } else if (score <= 0.4) {
        return `Prototyping Stage`;
      } else if (score <= 0.6) {
        return `Pilot Stage`;
      } else if (score <= 0.8) {
        return `Adoption Stage`;
      } else if (score <= 1.0) {
        return `Market Leadership Stage`;
      }
    }
    return "";
  };
  const getLvl = () => {
    if (score !== null) {
      if (score <= 0.2) {
        return `0-20: `;
      } else if (score <= 0.4) {
        return `21-40: `;
      } else if (score <= 0.6) {
        return `41-60: `;
      } else if (score <= 0.8) {
        return `61-80: `;
      } else if (score <= 1.0) {
        return `81-100: `;
      }
    }
    return "";
  };

  // API header
  const accessToken = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  // fetch score API
  useEffect(() => {
    // reset score
    setScore(null);
    if (pendingRequest) {
      pendingRequest.abort();
    }
    setPendingRequest(null);
    // API call
    const controller = new AbortController();
    const fetchScore = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/technology_readiness_index?phrases=${phrase}`,
        {
          headers,
          signal: controller.signal,
        }
      );
      return res;
    };
    setPendingRequest(controller);
    // handle request
    fetchScore()
      .then(({ data }) => {
        // update score
        setScore(Math.round((data + Number.EPSILON) * 100) / 100);
      })
      .catch((e) => {
        if (e.response && e.response.status === 403) {
          localStorage.removeItem("access_token");
          alert("Please Login Again.");
          window.location.reload();
        } else {
          console.warn(e);
        }
      });
    // eslint-disable-next-line
  }, [phrase]);

  // popper logic
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [isPopperShown, setIsPopperShown] = useState(false);
  const togglePopper = (evt) => {
    setPopperAnchor(evt.currentTarget);
    setIsPopperShown(true);
  };
  const closePopper = () => {
    if (isPopperShown) {
      setIsPopperShown(false);
    }
  };

  return (
    <Card elevation={1}>
      <Box
        sx={{
          position: "relative",
          fontFamily: "'Aileron', sans-serif",
          // p: "2rem",
          paddingLeft: "1rem",
          marginTop: "0.5rem",
          // maxWidth: "270px",
          mx: "auto",
        }}
      >
        {/* BETA Tag */}
        <Typography
          sx={{
            position: "absolute",
            top: "8px",
            padding: "2px 4px",
            backgroundColor: "#F2F8F7",
            color: "#0EA996",
            borderRadius: "4px 4px 4px 4px",
            fontSize: ".75rem",
            fontWeight: "600",
            fontFamily: "Aileron, sans-serif"
          }}
        >
          BETA
        </Typography>

        {/* existing component code */}
      </Box>
      <Box
        sx={{
          fontFamily: "'Glory', sans-serif",
          p: "2rem",
          maxWidth: "270px",
          mx: "auto",
        }}
      >
        {/* Title */}
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", mb: "1rem", textAlign: "center" }}
        >
          {phrase}
        </Typography>

        {score !== null ? (
          <div>
            {/* Top Half */}
            <Box sx={{ textAlign: "center" }}>
              {/* Tag */}
              <Chip
                label={getTag()}
                sx={{
                  fontFamily: '"Glory", sans-serif',
                  fontWeight: 400,
                  fontSize: "1.125rem",
                  color: getTagColor(),
                  backgroundColor: getTagBGColor(),
                }}
              ></Chip>

              {/* Progress Bar */}
              <Box sx={{ m: "1.5rem" }}>
                <SemiCirclePB progress={score}></SemiCirclePB>
              </Box>
            </Box>

            {/* Middle Text */}
            <Typography
              sx={{
                fontFamily: '"Glory", sans-serif',
                fontWeight: "500",
                fontSize: ".875rem",
                color: getTagColor(),
                mt: "1rem",
              }}
            >
              {getLvl() + getTag()}
            </Typography>
            <Typography
              sx={{
                fontFamily: '"Glory", sans-serif',
                fontSize: ".875rem",
                color: "#435452",
              }}
            >
              {getDesc()}
            </Typography>
          </div>
        ) : (
          <div>
            <Stack spacing={2}>
              <Skeleton variant="rounded"></Skeleton>
              <Skeleton variant="rounded" height={"6rem"}></Skeleton>
              <Skeleton variant="rounded"></Skeleton>
              <Skeleton variant="rounded" height={"6rem"}></Skeleton>
            </Stack>
          </div>
        )}

        {/* Bottom Text */}
        <Button
          variant="text"
          startIcon={<InfoOutlinedIcon />}
          size="small"
          sx={{
            display: "flex",
            color: "#00000066",
            ml: "-8px",
            alignItems: "center",
            fontFamily: '"Glory", sans-serif',
            fontSize: ".875rem",
            textTransform: "none",
          }}
          onClick={togglePopper}
        >
          What is the meaning of this index?
        </Button>
        {isPopperShown && (
          <TriCardPBPopper
            isOpen={isPopperShown}
            anchorEl={popperAnchor}
            clickAwayHandler={closePopper}
          ></TriCardPBPopper>
        )}
      </Box>
    </Card>
  );
}
